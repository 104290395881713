import Container from 'react-bootstrap/Container'
import React, { useEffect, useState } from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import { FormControl, InputGroup } from 'react-bootstrap'
import {
    MdPerson,
    MdWork,
    MdRoomService,
    MdAttachMoney,
    MdCreditCard,
    MdArchive, MdEmail, MdLocationCity, MdPlace, MdLocalPhone, MdLocationOn
} from 'react-icons/md'
import { IoMdArrowBack, IoMdCalendar } from 'react-icons/io'
import Select, { components } from 'react-select'
import { FaAngleDown } from 'react-icons/fa'
import moment from 'moment'
import MomentUtils from '@date-io/moment';
import {
    DatePicker,
    MuiPickersUtilsProvider,
} from "@material-ui/pickers";

import Palette from '../Palette'
import Member from '../models/Member'
import Cities from '../util/CityData'
import Provinces from '../util/ProvinceData'
import Swal from 'sweetalert2'
import { AiOutlineMail } from "react-icons/all";
import { LocationCity } from '@material-ui/icons'
import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/bootstrap.css'

const CITIZENSHIP_OPTION = [
    { label: 'Indonesian', value: 'INDONESIAN' },
    { label: 'Foreign', value: 'Foreign' },
]

function EditMembershipPage(props) {
    let memberModel = new Member()

    const fetchSelfData = async () => {
        let memberModel = new Member()
        try {
            let result = await memberModel.getMemberData()

            initializeData(result)
            console.log(result)
        } catch (e) {
            console.log('ERROR' + e)
        }
    }

    const DropdownIndicator = (props) => {
        return (
            components.DropdownIndicator && (
                <components.DropdownIndicator {...props}>
                    <FaAngleDown color={'grey'} />
                </components.DropdownIndicator>
            )
        )
    }

    const handleBackButton = () => {
        props.history.goBack()
    }

    const onBirthRegionChange = async (target) => {
        console.log('onBirthRegionChange has been initiated!')

        setBirthRegionOptions(Cities.filter(obj => {
            return obj.province_id === target
        }).map((value) => ({
            label: value.type + " " + value.city_name,
            value: value.city_id,
            province_id: value.province_id,
        })),)

    }

    const initializeData = (profileData) => {
        var date =
            profileData.member.birth_date &&
            moment(profileData.member.birth_date).format('YYYY-MM-DD')
        console.log(profileData)
        setEditHonorific(profileData.member.honorific ? profileData.member.honorific : undefined)
        setEditFullName(profileData.member.full_name ? profileData.member.full_name : undefined)
        setEditEmail(profileData.member.email ? profileData.member.email : undefined)
        setEditType(profileData.member.type ? profileData.member.type : undefined)
        setEditGender(profileData.member.gender ? profileData.member.gender : undefined)
        setEditBirthPlace(profileData.member.ss_created_location ? profileData.member.ss_created_location : undefined)
        setEditBirthDate(profileData.member.birth_date ? date : undefined)
        setEditNationality(profileData.member.birth_date ? date : undefined)
        console.log('profile data', profileData)
        setEditAddressStreet(
            profileData.member.adress_street ? profileData.member.address_street : undefined,
        )

        setEditIDAddress(
            profileData.member.ss_address ? profileData.member.ss_address : undefined,
        )

        setProvince(
            profileData.member.address_region ? profileData.member.address_region : undefined
        )

        setCity(
            profileData.member.address_locality ? profileData.member.address_locality : undefined
        )

        setEditCurrentAddress(
            profileData.member.address ? profileData.member.address : undefined,
        )

        setEditPhoneNumber(
            profileData.member.phone_number ? profileData.member.phone_number : undefined,
        )

        setEditHomePhoneNumber(
            profileData.member.landline_number ? profileData.member.landline_number : undefined,
        )

        setEditProfession(profileData.member.profession ? profileData.member.profession : undefined)
        setEditBirthPlace(profileData.member.birth_place ? profileData.member.birth_place : undefined)
        setCitizenship(profileData.member.citizenship ? profileData.member.citizenship : undefined)

    }
    const [allowEdit, setAllowEdit] = useState(true)
    const [editHonorific, setEditHonorific] = useState('')
    const [editFullName, setEditFullName] = useState('')
    const [editEmail, setEditEmail] = useState('')
    const [editType, setEditType] = useState('')
    const [editGender, setEditGender] = useState('')
    const [editBirthDate, setEditBirthDate] = useState('')
    const [editBirthPlace, setEditBirthPlace] = useState('')
    const [editNationality, setEditNationality] = useState('')
    const [editAddressStreet, setEditAddressStreet] = useState('')
    const [editIDAddress, setEditIDAddress] = useState('')
    const [editCurrentAddress, setEditCurrentAddress] = useState('')
    const [editPhoneNumber, setEditPhoneNumber] = useState("")
    const [editHomePhoneNumber, setEditHomePhoneNumber] = useState("")
    const [editProfession, setEditProfession] = useState('')

    const [isEditHonorificFocused, setEditHonorificFocused] = useState(false)
    const [isEditFullNameFocused, setEditFullNameFocused] = useState(false)
    const [isEditEmailFocused, setEditEmailFocused] = useState(false)
    const [isEditIDAddressFocused, setEditIDAddressFocused] = useState(false)
    const [isEditGenderFocused, setEditGenderFocused] = useState(false)
    const [isEditCurrentAddressFocused, setEditCurrentAddressFocused] = useState(false)
    const [isEditHomePhoneNumberFocused, setEditHomePhoneNumberFocused] = useState(false)
    const [isEditBirthPlaceFocused, setEditBirthPlaceFocused] = useState(false)
    const [isEditPhoneNumberFocused, setEditPhoneNumberFocused] = useState(false)

    const [citizenship, setCitizenship] = useState('INDONESIAN')
    const [isCitizenshipFocused, setIsCitizenshipFocused] = useState(false)

    const [provinces, setProvinces] = useState([])
    const [province, setProvince] = useState('')
    const [cities, setCities] = useState([])
    const [city, setCity] = useState('')


    const [addressLocalityOptions, setAddressLocalityOptions] = useState(Provinces.map((value) => ({
        label: value.province,
        value: value.province_id,
    })),)
    const [selectedAddressLocalityOptions, setSelectedAddressLocalityOptions] = useState([])
    const [addressRegionOptions, setAddressRegionOptions] = useState([])
    const [birthLocalityOptions, setBirthLocalityOptions] = useState(Provinces.map((value) => ({
        label: value.province,
        value: value.province_id,
    })))
    const [selectedBirthLocalityOptions, setSelectedBirthLocalityOptions] = useState([])
    const [birthRegionOptions, setBirthRegionOptions] = useState([])

    const getCities = async () => {
        let result = await memberModel.getMemberData()
        setCities([
            ...Cities.filter((city) => city.province === result.member.address_region).map((value) => ({
                label: value.type + ' ' + value.city_name,
                value: value.city_id,
                province_id: value.province_id,
            })),
        ])
    }

    useEffect(() => {

        fetchSelfData()
        setProvinces(
            Provinces.map((value) => ({ label: value.province, value: value.province_id })),
        )
        getCities()
        // setAddressRegionOptions(
        //     Cities.map((value) => ({
        //         label: value.city_name,
        //         value: value.city_id,
        //         province_id: value.province_id,
        //     })),
        // )

        // console.log(birthRegionOptions.filter((option) => option.label === editBirthRegion))
        // console.log(birthLocalityOptions.filter((option) => option.label === editBirthLocality))

        // console.log(addressRegionOptions.filter((option) => option.label === editAddressRegion))
        // console.log(addressLocalityOptions.filter((option) => option.label === editAddressLocality))
    }, [])

    const handleSave = async () => {

        let payload = {
            honorific: editHonorific,
            full_name: editFullName,
            email: editEmail,
            type: editType,
            gender: editGender,
            birth_date: editBirthDate,
            birth_place: editBirthPlace,
            ss_address: editIDAddress,
            address: editCurrentAddress,
            address_stress: editAddressStreet,
            ss_created_location: editNationality,
            phone_number: editPhoneNumber,
            landline_number: editHomePhoneNumber,
            citizenship: citizenship,
            address_region: province,
            address_locality: city
        }
        console.log(payload)

        try {
            let result = await memberModel.editMemberData(payload)

            Swal.fire({
                title: 'Profil tersimpan',
                icon: 'success',
                confirmButtonText: 'OKE',
            })
        } catch (e) {
            console.log(e)
        }
    }



    const onProvinceChange = (target) => {
        setCities([])
        setCity('-')

        console.log('onProvinceChange has been initiated!')
        console.log(target)
        console.log([...Cities.filter((city) => city.province_id === target)])

        setCities([
            ...Cities.filter((city) => city.province_id === target).map((value) => ({
                label: value.type + ' ' + value.city_name,
                value: value.city_id,
                province_id: value.province_id,
            })),
        ])
    }

    return (
        <Container className="text-left">
            <Row>
                <Button
                    style={{ backgroundColor: 'white', borderColor: 'white', color: 'black' }}
                    onClick={handleBackButton}
                    className="p-3"
                >
                    <IoMdArrowBack size={20} />
                </Button>
                <div
                    style={{
                        fontFamily: 'Signika',
                        fontWeight: '800',
                        fontSize: '1.75em',
                        color: Palette.PRIMARY,
                    }}
                    className="text-center w-100"
                >
                    Ubah Keanggotaan
                </div>
            </Row>

            <Row style={{ marginTop: 20 }}>
                <Col>
                    <div
                        style={{
                            fontFamily: 'Signika',
                            fontWeight: '600',
                            fontSize: '0.75em',
                            color: '#8e8e8e',
                            marginBottom: 7,
                        }}
                        className="mt-3 text-uppercase"
                    >
                        NAMA LENGKAP
                    </div>
                    {
                        allowEdit ? <InputGroup
                            style={
                                isEditFullNameFocused
                                    ? {
                                        boxShadow: '0 0 10px #9ecaed',
                                        border: '1px solid  #9ecaed',
                                        borderRadius: 5,
                                    }
                                    : {}
                            }
                        >
                            <InputGroup.Prepend>
                                <InputGroup.Text
                                    style={{
                                        borderWidth: 0,
                                        display: 'flex',
                                        alignItems: 'center',
                                        backgroundColor: '#e9f3f4',
                                    }}
                                >
                                    <MdPerson size={20} />
                                </InputGroup.Text>

                                <InputGroup.Text
                                    style={{
                                        borderWidth: 0,
                                        display: 'flex',
                                        alignItems: 'center',
                                        paddingLeft: 0,
                                        paddingRight: 0,
                                    }}
                                >
                                    <div
                                        style={{
                                            height: '75%',
                                            width: 2,
                                            backgroundColor: '#9ca4a6',
                                        }}
                                    />
                                </InputGroup.Text>
                            </InputGroup.Prepend>

                            <FormControl
                                placeholder={
                                    editFullName === undefined ? 'Fill your honorific' : editFullName
                                }
                                style={{
                                    borderWidth: 0,
                                    backgroundColor: '#e9f3f4',
                                    outline: 'none',
                                    boxShadow: 'none',
                                    fontFamily: 'Signika',
                                    fontWeight: '600',
                                }}
                                onChange={(event) => setEditFullName(event.target.value)}
                                onFocus={() => setEditFullNameFocused(true)}
                                onBlur={() => setEditFullNameFocused(false)}
                                value={editFullName}
                            />
                        </InputGroup> : editFullName
                    }


                    <div
                        style={{
                            fontFamily: 'Signika',
                            fontWeight: '600',
                            fontSize: '0.75em',
                            color: '#8e8e8e',
                            marginBottom: 7,
                        }}
                        className="mt-3 text-uppercase"
                    >
                        SUREL
                    </div>
                    {
                        allowEdit ? <InputGroup
                            style={
                                isEditEmailFocused
                                    ? {
                                        boxShadow: '0 0 10px #9ecaed',
                                        border: '1px solid  #9ecaed',
                                        borderRadius: 5,
                                    }
                                    : {}
                            }
                        >
                            <InputGroup.Prepend>
                                <InputGroup.Text
                                    style={{
                                        borderWidth: 0,
                                        display: 'flex',
                                        alignItems: 'center',
                                        backgroundColor: '#e9f3f4',
                                    }}
                                >
                                    <MdEmail size={20} />
                                </InputGroup.Text>

                                <InputGroup.Text
                                    style={{
                                        borderWidth: 0,
                                        display: 'flex',
                                        alignItems: 'center',
                                        paddingLeft: 0,
                                        paddingRight: 0,
                                    }}
                                >
                                    <div
                                        style={{
                                            height: '75%',
                                            width: 2,
                                            backgroundColor: '#9ca4a6',
                                        }}
                                    />
                                </InputGroup.Text>
                            </InputGroup.Prepend>

                            <FormControl
                                placeholder={editEmail === undefined ? 'Fill your email' : editEmail}
                                style={{
                                    borderWidth: 0,
                                    backgroundColor: '#e9f3f4',
                                    outline: 'none',
                                    boxShadow: 'none',
                                    fontFamily: 'Signika',
                                    fontWeight: '600',
                                }}
                                type="email"
                                onChange={(event) => setEditEmail(event.target.value)}
                                onFocus={() => setEditEmailFocused(true)}
                                onBlur={() => setEditEmailFocused(false)}
                                value={editEmail}
                            />
                        </InputGroup> : editEmail
                    }


                    <div
                        style={{
                            fontFamily: 'Signika',
                            fontWeight: '600',
                            fontSize: '0.75em',
                            color: '#8e8e8e',
                            marginBottom: 7,
                        }}
                        className="mt-3 text-uppercase"
                    >
                        Jenis kelamin
                    </div>
                    {
                        allowEdit ? <InputGroup
                            style={
                                isEditGenderFocused
                                    ? {
                                        boxShadow: '0 0 10px #9ecaed',
                                        border: '1px solid  #9ecaed',
                                        borderRadius: 5,
                                    }
                                    : {}
                            }
                        >
                            <div style={{ display: 'grid' }}>
                                <div>
                                    <input
                                        name="gender"
                                        type="radio"
                                        value="m"
                                        checked={editGender && editGender.toLowerCase() === 'm'}
                                        onClick={(event) => setEditGender(event.target.value)}
                                    />{' '}
                                    Laki-laki
                                </div>
                                <div>
                                    <input
                                        name="gender"
                                        type="radio"
                                        value="f"
                                        checked={editGender && editGender.toLowerCase() === 'f'}
                                        onClick={(event) => setEditGender(event.target.value)}
                                    />{' '}
                                    Perempuan
                                </div>
                            </div>
                        </InputGroup> : editGender.toLocaleLowerCase() === 'm' ? 'Laki Laki' : 'Perempuan'
                    }


                    <div
                        style={{
                            fontFamily: 'Signika',
                            fontWeight: '600',
                            fontSize: '0.75em',
                            color: '#8e8e8e',
                            marginBottom: 7,
                        }}
                        className="mt-3 text-uppercase"
                    >
                        Tempat lahir
                    </div>
                    {allowEdit ? <InputGroup>
                        <InputGroup.Prepend>
                            <InputGroup.Text
                                style={{
                                    borderWidth: 0,
                                    display: 'flex',
                                    alignItems: 'center',
                                    backgroundColor: '#e9f3f4',
                                }}
                            >
                                <LocationCity size={20} />
                            </InputGroup.Text>

                            <InputGroup.Text
                                style={{
                                    borderWidth: 0,
                                    display: 'flex',
                                    alignItems: 'center',
                                    paddingLeft: 0,
                                    paddingRight: 0,
                                }}
                            >
                                <div
                                    style={{
                                        height: '75%',
                                        width: 2,
                                        backgroundColor: '#9ca4a6',
                                    }}
                                />
                            </InputGroup.Text>
                        </InputGroup.Prepend>

                        <FormControl
                            placeholder={editBirthPlace === undefined ? 'Masukan Tempat Lahir anda' : editBirthPlace}
                            style={{
                                borderWidth: 0,
                                backgroundColor: '#e9f3f4',
                                outline: 'none',
                                boxShadow: 'none',
                                fontFamily: 'Signika',
                                fontWeight: '600',
                            }}
                            type="email"
                            onChange={(event) => setEditBirthPlace(event.target.value)}
                            onFocus={() => setEditBirthPlaceFocused(true)}
                            onBlur={() => setEditBirthPlaceFocused(false)}
                            value={editBirthPlace}
                        />
                    </InputGroup> : editBirthPlace}



                    <div
                        style={{
                            fontFamily: 'Signika',
                            fontWeight: '600',
                            fontSize: '0.75em',
                            color: '#8e8e8e',
                            marginBottom: 7,
                        }}
                        className="mt-3 text-uppercase"
                    >
                        Tanggal lahir
                    </div>
                    {/* {moment(editBirthDate).format('DD/MM/YYYY')} */}
                    {/* <InputGroup>
                        <InputGroup.Prepend>
                            <InputGroup.Text
                                style={{
                                    borderWidth: 0,
                                    display: 'flex',
                                    alignItems: 'center',
                                    backgroundColor: '#e9f3f4',
                                }}
                            >
                                <IoMdCalendar size={20}/>
                            </InputGroup.Text>
                        </InputGroup.Prepend>

                        <div style={{flex: 1}}>
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                            <DatePicker
                                disableFuture
                                openTo="year"
                                format="DD/MM/YYYY"
                                views={["year", "month", "date"]}
                                value={editBirthDate}
                                onChange={setEditBirthDate}
                                className='w-100'
                                style={{
                                    background: '#e9f3f4'
                                }}
                            />
                        </MuiPickersUtilsProvider>
                        </div>
                    </InputGroup> */}

                    <InputGroup
                        style={{
                                boxShadow: '0 0 10px #9ecaed',
                                border: '1px solid  #9ecaed',
                                borderRadius: 5,
                            }}
                    >
                        <InputGroup.Prepend>
                            <InputGroup.Text
                                style={{
                                    borderWidth: 0,
                                    display: 'flex',
                                    alignItems: 'center',
                                    backgroundColor: '#e9f3f4',
                                }}
                            >
                                <IoMdCalendar size={20} />
                            </InputGroup.Text>

                            <InputGroup.Text
                                style={{
                                    borderWidth: 0,
                                    display: 'flex',
                                    alignItems: 'center',
                                    paddingLeft: 0,
                                    paddingRight: 0,
                                }}
                            >
                                <div
                                    style={{
                                        height: '75%',
                                        width: 2,
                                        backgroundColor: '#9ca4a6',
                                    }}
                                />
                            </InputGroup.Text>
                        </InputGroup.Prepend>

                        <FormControl
                            placeholder={
                                editBirthDate === undefined ? 'Fill your Birth Date' : editBirthDate
                            }
                            style={{
                                borderWidth: 0,
                                backgroundColor: '#e9f3f4',
                                outline: 'none',
                                boxShadow: 'none',
                                fontFamily: 'Signika',
                                fontWeight: '600',
                            }}
                            type="date"
                            onChange={(event) => setEditBirthDate(event.target.value)}
                            // onFocus={() => setEditBirthDateFocused(true)}
                            // onBlur={() => setEditBirthDateFocused(false)}
                            value={editBirthDate}
                        />
                    </InputGroup>

                    <div className="register-form-label mt-3">PROVINSI</div>
                    {
                        allowEdit ? <InputGroup>
                            <InputGroup.Prepend>
                                <InputGroup.Text
                                    style={{
                                        borderWidth: 0,
                                        display: 'flex',
                                        alignItems: 'center',
                                        backgroundColor: '#e9f3f4',
                                    }}
                                >
                                    <MdLocationOn size={20} />
                                </InputGroup.Text>
                            </InputGroup.Prepend>

                            <div style={{ flex: 1 }}>
                                <Select
                                    placeholder={'Select Province'}
                                    options={provinces}
                                    onChange={(e) => {
                                        setProvince(e.label)
                                        onProvinceChange(e.value);
                                        // console.log(province);
                                    }}
                                    styles={{
                                        option: (provided, state) => ({
                                            ...provided,
                                            cursor: 'pointer',
                                        }),
                                        control: (provided) => ({
                                            ...provided,
                                            borderWidth: 0,
                                            backgroundColor: '#e9f3f4',
                                            fontFamily: 'Signika',
                                            fontWeight: '600',
                                            color: '#8e8e8e',
                                        }),
                                    }}
                                    value={provinces.find(p => province === p.label)}
                                    components={{
                                        DropdownIndicator,
                                        IndicatorSeparator: () => null,
                                    }}
                                />
                            </div>
                        </InputGroup> : province
                    }

                    <div className="register-form-label mt-3">KOTA/KABUPATEN</div>
                    {
                        allowEdit ? <InputGroup>
                            <InputGroup.Prepend>
                                <InputGroup.Text
                                    style={{
                                        borderWidth: 0,
                                        display: 'flex',
                                        alignItems: 'center',
                                        backgroundColor: '#e9f3f4',
                                    }}
                                >
                                    <MdLocationOn size={20} />
                                </InputGroup.Text>
                            </InputGroup.Prepend>

                            <div style={{ flex: 1 }}>
                                <Select
                                    placeholder={'Select City / Regency'}
                                    options={cities}
                                    value={cities.find(c => city === c.label) || null}
                                    onChange={(e) => {
                                        setCity(e.label)
                                        // console.log(province);
                                    }}
                                    styles={{
                                        option: (provided, state) => ({
                                            ...provided,
                                            cursor: 'pointer',
                                        }),
                                        control: (provided) => ({
                                            ...provided,
                                            borderWidth: 0,
                                            backgroundColor: '#e9f3f4',
                                            fontFamily: 'Signika',
                                            fontWeight: '600',
                                            color: '#8e8e8e',
                                        }),
                                    }}
                                    components={{
                                        DropdownIndicator,
                                        IndicatorSeparator: () => null,
                                    }}
                                />
                            </div>
                        </InputGroup>
                            : city
                    }

                    <div
                        style={{
                            fontFamily: 'Signika',
                            fontWeight: '600',
                            fontSize: '0.75em',
                            color: '#8e8e8e',
                            marginBottom: 7,
                        }}
                        className="mt-3 text-uppercase"
                    >
                        Alamat sesuai KTP
                    </div>
                    {
                        allowEdit ? <InputGroup
                            style={
                                isEditIDAddressFocused
                                    ? {
                                        boxShadow: '0 0 10px #9ecaed',
                                        border: '1px solid  #9ecaed',
                                        borderRadius: 5,
                                    }
                                    : {}
                            }
                        >
                            <InputGroup.Prepend>
                                <InputGroup.Text
                                    style={{
                                        borderWidth: 0,
                                        display: 'flex',
                                        alignItems: 'center',
                                        backgroundColor: '#e9f3f4',
                                    }}
                                >
                                    <MdPlace size={20} />
                                </InputGroup.Text>

                                <InputGroup.Text
                                    style={{
                                        borderWidth: 0,
                                        display: 'flex',
                                        alignItems: 'center',
                                        paddingLeft: 0,
                                        paddingRight: 0,
                                    }}
                                >
                                    <div
                                        style={{
                                            height: '75%',
                                            width: 2,
                                            backgroundColor: '#9ca4a6',
                                        }}
                                    />
                                </InputGroup.Text>
                            </InputGroup.Prepend>

                            <FormControl
                                placeholder={editIDAddress}
                                style={{
                                    borderWidth: 0,
                                    backgroundColor: '#e9f3f4',
                                    outline: 'none',
                                    boxShadow: 'none',
                                    fontFamily: 'Signika',
                                    fontWeight: '600',
                                }}
                                onChange={(event) => setEditIDAddress(event.target.value)}
                                onFocus={() => setEditIDAddressFocused(true)}
                                onBlur={() => setEditIDAddressFocused(false)}
                                value={editIDAddress}
                                as="textarea"
                            />
                        </InputGroup> : editIDAddress
                    }


                    <div
                        style={{
                            fontFamily: 'Signika',
                            fontWeight: '600',
                            fontSize: '0.75em',
                            color: '#8e8e8e',
                            marginBottom: 7,
                        }}
                        className="mt-3 text-uppercase"
                    >
                        Alamat Sekarang (Jika berbeda dengan KTP)
                    </div>
                    {
                        allowEdit ? <InputGroup
                            style={
                                isEditCurrentAddressFocused
                                    ? {
                                        boxShadow: '0 0 10px #9ecaed',
                                        border: '1px solid  #9ecaed',
                                        borderRadius: 5,
                                    }
                                    : {}
                            }
                        >
                            <InputGroup.Prepend>
                                <InputGroup.Text
                                    style={{
                                        borderWidth: 0,
                                        display: 'flex',
                                        alignItems: 'center',
                                        backgroundColor: '#e9f3f4',
                                    }}
                                >
                                    <MdPlace size={20} />
                                </InputGroup.Text>

                                <InputGroup.Text
                                    style={{
                                        borderWidth: 0,
                                        display: 'flex',
                                        alignItems: 'center',
                                        paddingLeft: 0,
                                        paddingRight: 0,
                                    }}
                                >
                                    <div
                                        style={{
                                            height: '75%',
                                            width: 2,
                                            backgroundColor: '#9ca4a6',
                                        }}
                                    />
                                </InputGroup.Text>
                            </InputGroup.Prepend>

                            <FormControl
                                placeholder={editCurrentAddress}
                                style={{
                                    borderWidth: 0,
                                    backgroundColor: '#e9f3f4',
                                    outline: 'none',
                                    boxShadow: 'none',
                                    fontFamily: 'Signika',
                                    fontWeight: '600',
                                }}
                                onChange={(event) => setEditCurrentAddress(event.target.value)}
                                onFocus={() => setEditCurrentAddressFocused(true)}
                                onBlur={() => setEditCurrentAddressFocused(false)}
                                value={editCurrentAddress}
                                as="textarea"
                            />
                        </InputGroup> : editCurrentAddress
                    }


                    <div className="register-form-label mt-3">KEWARGANEGARAAN</div>
                    {
                        allowEdit ? <InputGroup>
                            <InputGroup.Prepend>
                                <InputGroup.Text
                                    style={{
                                        borderWidth: 0,
                                        display: 'flex',
                                        alignItems: 'center',
                                        backgroundColor: '#e9f3f4',
                                    }}
                                >
                                    <MdLocationOn size={20} />
                                </InputGroup.Text>
                            </InputGroup.Prepend>

                            <div style={{ flex: 1 }}>
                                <Select
                                    placeholder={'Select Citizenship'}
                                    options={CITIZENSHIP_OPTION}
                                    onChange={(e) => {
                                        setCitizenship(e.value)
                                    }}
                                    styles={{
                                        option: (provided, state) => ({
                                            ...provided,
                                            cursor: 'pointer',
                                        }),
                                        control: (provided) => ({
                                            ...provided,
                                            borderWidth: 0,
                                            backgroundColor: '#e9f3f4',
                                            fontFamily: 'Signika',
                                            fontWeight: '600',
                                            color: '#8e8e8e',
                                        }),
                                    }}
                                    value={CITIZENSHIP_OPTION.find(c => citizenship === c.value)}
                                    components={{
                                        DropdownIndicator,
                                        IndicatorSeparator: () => null,
                                    }}
                                />
                            </div>
                        </InputGroup>
                            : citizenship
                    }

                    <div
                        style={{
                            fontFamily: 'Signika',
                            fontWeight: '600',
                            fontSize: '0.75em',
                            color: '#8e8e8e',
                            marginBottom: 7,
                        }}
                        className="mt-3 text-uppercase"
                    >
                        NOMOR TELEPON SELULER
                    </div>
                    <InputGroup
                        /* style={
                            isEditPhoneNumberFocused
                                ? {
                                    boxShadow: '0 0 10px #9ecaed',
                                    border: '1px solid  #9ecaed',
                                    borderRadius: 5,
                                }
                                : {}
                        } */
                    >

                        {/* <InputGroup.Prepend>
                            <InputGroup.Text
                                style={{
                                    borderWidth: 0,
                                    display: 'flex',
                                    alignItems: 'center',
                                    backgroundColor: '#e9f3f4',
                                }}
                            >
                                <MdLocalPhone size={20} />
                            </InputGroup.Text>

                            <InputGroup.Text
                                style={{
                                    borderWidth: 0,
                                    display: 'flex',
                                    alignItems: 'center',
                                    paddingLeft: 0,
                                    paddingRight: 0,
                                }}
                            >
                                <div
                                    style={{
                                        height: '75%',
                                        width: 2,
                                        backgroundColor: '#9ca4a6',
                                    }}
                                />
                            </InputGroup.Text>
                        </InputGroup.Prepend>

                        <FormControl
                            placeholder={
                                editPhoneNumber === undefined
                                    ? 'Isi nomor telepon'
                                    : editPhoneNumber
                            }
                            style={{
                                borderWidth: 0,
                                backgroundColor: '#e9f3f4',
                                outline: 'none',
                                boxShadow: 'none',
                                fontFamily: 'Signika',
                                fontWeight: '600',
                            }}
                            type="tel"
                            onChange={(event) => setEditPhoneNumber(event.target.value)}
                            onFocus={() => setEditPhoneNumberFocused(true)}
                            onBlur={() => setEditPhoneNumberFocused(false)}
                            value={editPhoneNumber}
                        /> */}
                        <PhoneInput
                                placeholder='Isi nomor telepon'
                                className="form-control-custom"
                                countryCodeEditable={true}
                                autoFormat={false}
                                country={'id'}
                                preferredCountries={['id']}
                                preserveOrder={['preferredCountries']}
                                disableDropdown={false}
                                value={editPhoneNumber} onChange={(e) => setEditPhoneNumber(e)}
                                    inputStyle={{
                                        borderWidth: "0",
                                        backgroundColor: "#e9f3f4",
                                        outline: "none",
                                        boxShadow: "none",
                                        fontFamily: "Signika",
                                        fontWeight: "600",
                                        
                                    }} 
                                buttonStyle={{backgroundColor: "lightGray", }}/>
                    
                    </InputGroup> 
                    

                    <div
                        style={{
                            fontFamily: 'Signika',
                            fontWeight: '600',
                            fontSize: '0.75em',
                            color: '#8e8e8e',
                            marginBottom: 7,
                        }}
                        className="mt-3 text-uppercase"
                    >
                        Nomor Telepon Kedua (opsional)
                    </div>
                    <InputGroup
                        /* style={
                            isEditHomePhoneNumberFocused
                                ? {
                                    boxShadow: '0 0 10px #9ecaed',
                                    border: '1px solid  #9ecaed',
                                    borderRadius: 5,
                                }
                                : {}
                        } */
                    >
                        {/* <InputGroup.Prepend>
                            <InputGroup.Text
                                style={{
                                    borderWidth: 0,
                                    display: 'flex',
                                    alignItems: 'center',
                                    backgroundColor: '#e9f3f4',
                                }}
                            >
                                <MdLocalPhone size={20} />
                            </InputGroup.Text>

                            <InputGroup.Text
                                style={{
                                    borderWidth: 0,
                                    display: 'flex',
                                    alignItems: 'center',
                                    paddingLeft: 0,
                                    paddingRight: 0,
                                }}
                            >
                                <div
                                    style={{
                                        height: '75%',
                                        width: 2,
                                        backgroundColor: '#9ca4a6',
                                    }}
                                />
                            </InputGroup.Text>
                        </InputGroup.Prepend>

                        <FormControl
                            placeholder={
                                editHomePhoneNumber === undefined
                                    ? 'Isi nomor telepon kedua'
                                    : editHomePhoneNumber
                            }
                            style={{
                                borderWidth: 0,
                                backgroundColor: '#e9f3f4',
                                outline: 'none',
                                boxShadow: 'none',
                                fontFamily: 'Signika',
                                fontWeight: '600',
                            }}
                            type="tel"
                            onChange={(event) => setEditHomePhoneNumber(event.target.value)}
                            onFocus={() => setEditHomePhoneNumberFocused(true)}
                            onBlur={() => setEditHomePhoneNumberFocused(false)}
                            value={editHomePhoneNumber}
                        /> */}

                            <PhoneInput
                                placeholder='Isi nomor telepon'
                                className="form-control-custom"
                                countryCodeEditable={false}
                                autoFormat={false}
                                country={'id'}
                                preferredCountries={['id']}
                                preserveOrder={['preferredCountries']}
                                disableDropdown={false}
                                value={editHomePhoneNumber} onChange={(e) => setEditHomePhoneNumber(e)}
                                    inputStyle={{
                                        borderWidth: "0",
                                        backgroundColor: "#e9f3f4",
                                        outline: "none",
                                        boxShadow: "none",
                                        fontFamily: "Signika",
                                        fontWeight: "600",
                                        
                                    }} 
                                buttonStyle={{backgroundColor: "lightGray", }}/>
                    </InputGroup>

                    <Row
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            marginTop: 25,
                            marginBottom: 20,
                        }}
                    >
                        <Button
                            style={{
                                fontFamily: 'Signika',
                                fontWeight: '600',
                                paddingTop: 10,
                                paddingBottom: 10,
                                backgroundColor: Palette.PRIMARY,
                                borderColor: Palette.PRIMARY,
                            }}
                            className="w-100 text-uppercase"
                            onClick={handleSave}
                        >
                            Simpan
                        </Button>
                    </Row>
                </Col>
            </Row>
        </Container>
    )
}

export default EditMembershipPage
