import {Button, Container} from 'react-bootstrap'
import React, {useMemo, useEffect, useState, useRef} from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import jsPDF from 'jspdf'
import html2canvas from 'html2canvas'
import Modal from 'react-bootstrap/Modal'
import moment from 'moment'
import Palette from "../../Palette";
import goldMemberCard from "../../assets/member_card_yellow.png"
import silverMemberCard from "../../assets/member_card_silver.png"
import blueMemberCard from "../../assets/member_card_blue.png"

export default function MemberCardModalV2(props) {

  const {show, close, data} = props

  console.log("datax", data)

  const [refWidth, setRefWidth] = useState(0)
  const [trigger, setTrigger] = useState("red")

  const cardRef = useRef(null)

  const processLanguagePair = () => {
    let languagesToReturnPair = [] //{from, to, two_way}
    if(data && data.languages){
      for(let l of data.languages){
        let found = 0
        for(let ltrIndex in languagesToReturnPair){
          let ltr = languagesToReturnPair[ltrIndex]
          if((ltr.from === l.language_from && ltr.to === l.language_to) || (ltr.to === l.language_from && ltr.from === l.language_to)){
            found = 1
            languagesToReturnPair[ltrIndex].two_way = true
          }
        }
        if(!found){
          // languagesToReturnPair
          languagesToReturnPair.push({
            from : l.language_from,
            to : l.language_to,
            two_way : false
          })
        }
      }
    }
    console.log("LTRP", languagesToReturnPair)
    return languagesToReturnPair
  }

  processLanguagePair()

  let cardColor, fontcolor
  if(data.role == "MUDA"){
    cardColor = goldMemberCard
    fontcolor = "#cca811"
  }else if(data.role === "MITRA"){
    cardColor = blueMemberCard
    fontcolor = "#2196f3"
  }else{
    cardColor = silverMemberCard
    fontcolor = "#607d8b"
  }

  function capitalizeStr(str){
  let string
  if(str === undefined){
    string = String(data.role)
  }else{
    string = String(str)
  }
  let membership_role = string[0].toUpperCase() + string.slice(1).toLowerCase();
  return membership_role
  }

  function validateMemberCard(memberCard, nonMemberCard){
    if(memberCard === undefined){
      return moment(nonMemberCard).format("YYYY")
      }else{
      return moment(memberCard).format("YYYY")}
    }

  useEffect(() => {
    if (cardRef) {
      console.log("cardref UE", cardRef)
      // setWidth()
    }
  }, [cardRef])

  useEffect(() => {
    if(props.show){
      setTimeout(() => {
        console.log("triggering")
        setTrigger(trigger === "red" ? "yellow" : "red")
      }, 500)
    }
  }, [props.show])

  const handleClose = () => {
    close()
  }

  const downloadCard = async () => {
    const element = document.getElementById('print-card'),
      canvas = await html2canvas(element, {useCORS: true, scale : 4}),
      data = canvas.toDataURL('image/jpg');
    let pdf = new jsPDF('l', 'mm', [320, 560])//70,40
    /*let pdf = new jsPDF({
      format: [80, 140],
      orientation: 'l',
    });*/
    pdf.addImage(data, 'PNG', 0, 0, 200, 114);
    // pdf.output('dataurlnewwindow');
    pdf.save("download.pdf");

    // let pdf = new jsPDF({
    //   orientation: "landscape",
    //   unit: "in",
    // });
    // pdf.html(document.getElementById('myTable'), {
    //   callback: function (pdf) {
    //     pdf.save(data.member.id + '-' + data.full_name);
    //   }
    // });

  }

  let cardHeight = refWidth ? refWidth * 600 / 1051 : 1

  return <>

    <Modal
      show={show}
      onHide={handleClose}
      size={'lg'}
    >
      <Modal.Header closeButton>
        Kartu Anggota {data?.full_name}
      </Modal.Header>
      <Modal.Body>
        <div
          id="print-card"
          style={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            color : trigger,
            background: cardRef ? null : trigger,
          }}
          ref={cardRef}
        >
          <div
            className={"d-flex flex-column"}
            ref={(ref) => {

              console.log("cardref2", ref?.clientWidth)

              if (ref && refWidth === 0) {
                console.log("cardref", ref?.clientWidth)
                setRefWidth(ref.clientWidth)
              }
            }}
            style={{
              background: `url(${cardColor})`,
              height: cardHeight,
              backgroundSize: "contain",
              width: "100%"
            }}>
            <Container style={{paddingLeft : refWidth * 0.05}}>
              <Row style={{marginTop: cardHeight * 0.4, color: Palette.GOLD}}>
                <Col
                    className={"d-flex flex-column"}
                    md={7} style={{color: fontcolor}}>
                  <div style={{fontWeight:500,fontSize : cardHeight * 0.075, lineHeight : 1.05, color: fontcolor, textTransform: 'capitalize'}} className={"fira-sans"}>{data?.full_name}</div>
                  {
                    data?.profession === "Both" ?
                      <>
                        <div style={{fontSize : cardHeight * 0.035, lineHeight : 1.1, color: fontcolor}} className={"proxima-nova"}>Penerjemah/<i className={"proxima-novai"}>Translator</i></div>
                        <div style={{fontSize : cardHeight * 0.035, lineHeight : 1.1, color: fontcolor}} className={"proxima-nova"}>Juru Bahasa/<i className={"proxima-nova-i"}>Interpreter</i></div>
                      </>
                      :
                      data?.profession === "Translator" ?
                        <div style={{fontSize : cardHeight * 0.04, color: fontcolor}} className={"proxima-nova"}>Penerjemah/<i className={"proxima-nova-i"}>Translator</i></div>
                        :
                        <div style={{fontSize : cardHeight * 0.04, color: fontcolor}} className={"proxima-nova"}>Juru Bahasa/<i className={"proxima-nova-i"}>Interpreter</i></div>
                  }
                </Col>
                <Col
                  className={"d-flex flex-column"}
                  md={5}
                >
                  <div style={{fontWeight:700,color : Palette.NEON_BLUE, fontSize : cardHeight * 0.03}} className={"proxima-nova"}>No. Anggota/<i className={"proxima-nova-i"}>Membership ID</i></div>
                  <div style={{fontWeight:500,fontSize : cardHeight * 0.06, lineHeight : 1, color: fontcolor}} className={"fira-sans"}>{data?.hpi_id}</div>
                </Col>
              </Row>
              <Row style={{marginTop : cardHeight * 0.06}}>
                <Col
                  className={"d-flex flex-column"}
                  md={6}>
                  <div style={{fontWeight:700,color : Palette.NEON_BLUE, fontSize : cardHeight * 0.03}} className={"proxima-nova"}>
                    Tingkat Keanggotaan/<i>Membership Level</i>
                  </div>
                  <div style={{fontWeight:500,fontSize : cardHeight * 0.06, lineHeight : 1, color: fontcolor}} className={"fira-sans"}>
                  Anggota {capitalizeStr(data?.member_card?.membership_role)}
                  </div>
                  <div style={{fontWeight:700,color : Palette.NEON_BLUE, fontSize : cardHeight * 0.03, marginTop : cardHeight * 0.07}} className={"proxima-nova"}>
                    Masa Berlaku Kartu/<i>Card Validity</i>
                  </div>
                  <div style={{fontWeight:500,fontSize : cardHeight * 0.06, lineHeight : 1, color: fontcolor}} className={"fira-sans"}>
                    {moment().format("YYYY")}
                  </div>
                </Col>
                <Col md={6}>
                  <div style={{fontWeight:700,color : Palette.NEON_BLUE, fontSize : cardHeight * 0.03}} className={"proxima-nova"}>
                    Pasangan Bahasa/<i>Language Pair(s)</i>
                  </div>
                  <div style={{fontWeight:500,fontSize : cardHeight * 0.05, lineHeight : 1.05, color: fontcolor}} className={"fira-sans"}>
                    {processLanguagePair().map((obj,key)=>{
                      return <div key={key}>{obj.from}{obj.two_way ? " <> " : " > "}{obj.to}</div>
                    })}
                  </div>
                </Col>
              </Row>
            </Container>

          </div>
        </div>
      </Modal.Body>

      <Modal.Footer>
        <Button onClick={downloadCard}>
          Unduh kartu elektronik
        </Button>
      </Modal.Footer>
    </Modal>
  </>

}