import Container from 'react-bootstrap/Container'
import React, { useEffect, useState } from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { FormControl, InputGroup, Form } from 'react-bootstrap'
import {
    MdEmail,
    MdHome,
    MdLocalPostOffice,
    MdLocationCity,
    MdLocationOn,
    MdLock,
    MdPerson,
    MdWork,
    MdErrorOutline,
    MdPhone,
    MdCardMembership,
    MdPhoneIphone,
    MdHelp,
} from 'react-icons/md'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import { IoMdEye, IoMdEyeOff } from 'react-icons/io'
import Location from '../models/Location'
import Select, { components } from 'react-select'
import Spinner from 'react-bootstrap/Spinner'
import { FaAngleDown } from 'react-icons/fa'
import Button from 'react-bootstrap/Button'

import COUNTRIES_JSON from '../assets/countries/countries.json'
import Palette from '../Palette'
import Specialization from '../models/Specialization'
import Language from '../models/Language'
import Member from '../models/Member'
import Cities from '../util/CityData'
import Provinces from '../util/ProvinceData'
import Swal from 'sweetalert2'
import Alert from '../components/Alert'
import { useHistory } from 'react-router-dom'
import { FaMinus, FaPlus } from 'react-icons/all'
import IconButton from '@material-ui/core/IconButton'
import MomentUtils from '@date-io/moment'
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import '../pages/css/register.css'
import Upload from '../models/Upload'
import moment from 'moment'
import { Radio } from '@material-ui/core'
import CustomDropzone from '../components/Reusable/CustomDropzone'

const translatorFullMemberReqOptions = [
    {
        label: '1. Telah menerjemahkan buku dan diterbitkan oleh penerbit terdaftar IKAPI.',
        value: '1',
    },
    {
        label: '2. Melampirkan contoh terjemahan (beserta teks sumber) dengan volume 150.000 karakter termasuk spasi.',
        value: '2',
    },
    { label: '3. Merupakan Penerjemah Tersumpah.', value: '3' },
    {
        label: '4. Telah bekerja (minimal) satu tahun di sebuah perusahaan sebagai penerjemah atau jabatan terkait penerjemahan.',
        value: '4',
    },
    { label: '5. Surat rujukan/referensi dari 3 (tiga) klien yang berbeda.', value: '5' },
]

function PrivacyPolicyPage(props) {
    const history = useHistory()

    const DropdownIndicator = (props) => {
        return (
            components.DropdownIndicator && (
                <components.DropdownIndicator {...props}>
                    <FaAngleDown color={'grey'} />
                </components.DropdownIndicator>
            )
        )
    }

    return (
        <Container>
            <Row className="page-title">Kebijakan Privasi</Row>

            <Row style={{ marginTop: 20 }}>
                <Col xl={12} lg={12} md={12} xs={12} className={'m-auto'}>
                    getlorem.com is committed to protecting your privacy online. This Privacy Policy
                    endeavours to describe to you our practices regarding the personal information
                    we collect from users on our website, located at getlorem.com (the “Site”), and
                    the services offered through the Site. If you have any questions about our
                    Privacy Policy, our collection practices, the processing of user information, or
                    if you would like to report a security violation to us directly, please contact
                    us at hello@lukehaas.me
                </Col>
            </Row>

            <Row>
                <Col xl={12} lg={12} md={12} xs={12} className={'m-auto'}>
                    <h3>What Data We Collect</h3>

                    <p>
                        <b>General Data:</b> The use of our services will automatically create information
                        that will be collected. For example, when you use our Services, your
                        geographic location, how you use the Services, information about the type of
                        device you use, your Open Device Identification Number, date/time stamps for
                        your visit, your unique device identifier, your browser type, operating
                        system, Internet Protocol (IP) address, and domain name are all collected.
                        This information is generally used to help us deliver the most relevant
                        information to you and administer and improve the Site.
                    </p>
                    <p>
                        <b>Log Files:</b> As is true of most websites, we gather certain information
                        automatically and store it in log files. This information includes IP
                        addresses, browser type, Internet service provider (ISP), referring/exit
                        pages, operating system, date/time stamp, and clickstream data. We use this
                        information to maintain and improve the performance of the Services.
                    </p>
                    <p>
                        <b>Analytics:</b> We use analytics services (including, but not limited to, Google
                        Analytics) to help analyze how users use the Site. Analytics services use
                        Cookies to collect information such as how often users visit the Site and we
                        use the information to improve our Site and Services. The analytics
                        services' ability to use and share information collected by them is
                        restricted by their terms of use and privacy policy, which you should refer
                        to for more information about how these entities use this information.
                    </p>
                    <p>
                        <b>Location Information:</b> If you have enabled location services on your mobile
                        device, we may collect your location information to improve the Services we
                        offer. If you do not want this information collected, you can disable
                        location services on your device.
                    </p>
                    <p>
                        <b>Cookies:</b> “Cookies” are small pieces of information (text files) that a
                        website sends to your computer’s hard drive while you are viewing the
                        website. These text files can be used by websites to make the users
                        experience more efficient. The law states that we can store these cookies on
                        your device if they are strictly necessary for the operation of this site.
                        For all other types of cookies we need your permission. To that end, this
                        site uses different types of cookies. Some cookies are placed by third party
                        services that appear on our pages. We and some third parties may use both
                        session Cookies (which expire once you close your web browser) and
                        persistent Cookies (which stay on your computer until you delete them) to
                        provide you with a more personal and interactive experience on our Services
                        and to market the Services or other products. Marketing cookies are used to
                        track visitors across websites. The intention is to display ads that are
                        relevant and engaging for the individual user and thereby more valuable for
                        publishers and third party advertisers. This tracking is done on an
                        anonymous basis and, while not an exhaustive list, some of the companies we
                        work with are Google, AppNexus, Criteo, Rubicon, Pubmatic and DistrictM. To
                        learn more about this practice, including the Self Regulatory Principles for
                        Online Advertising, to which we adhere, you can visit
                        www.aboutads.info/choices, optout.networkadvertising.org and
                        www.youronlinechoices.com
                    </p>
                </Col>
            </Row>
        </Container>
    )
}

export default PrivacyPolicyPage
