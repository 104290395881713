import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import React, { useEffect, useState } from 'react'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import { InputGroup, Form, Dropdown, DropdownButton, FormControl } from 'react-bootstrap'
import { MdSearch } from 'react-icons/md'
import { BiFilterAlt } from 'react-icons/bi'
import { useHistory } from 'react-router-dom'
import PhoneInput from "react-phone-input-2";
import Cities from '../util/CityData'
import Provinces from '../util/ProvinceData'

import Swal from 'sweetalert2'
import {
    MdEmail,
    MdHome,
    MdLocalPostOffice,
    MdLocationCity,
    MdLocationOn,
    MdLock,
    MdPerson,
    MdWork,
    MdErrorOutline,
} from 'react-icons/md'
import { IoMdEye, IoMdEyeOff } from 'react-icons/io'

import Palette from '../Palette'
import './css/HomePage.css'
import Specialization from '../models/Specialization'
import Language from '../models/Language'
import Select, { components } from 'react-select'
import { FaAngleDown } from 'react-icons/fa'
import Member from '../models/Member'
import Spinner from "react-bootstrap/Spinner";

function WelcomeMigrationPage(props) {
    let history = useHistory()

    const query = new URLSearchParams(props.location.search);
    
    const activation_token = query.get("activation_token")
    const hpi_id = query.get("hpi_id")

    const [province, setProvince] = useState('')
    const [provinces, setProvinces] = useState([])
    const [provinceError, setProvinceError] = useState('')

    const [city, setCity] = useState('')
    const [cities, setCities] = useState([])
    const [cityError, setCityError] = useState('')

    const [fullName, setFullName] = useState('')
    const [email, setEmail] = useState('')
    const [homePhone, setHomePhone] = useState('')
    const [mobilePhone, setMobilePhone] = useState('')
    const [currentAddress, setCurrentAddress] = useState('')
    const [role, setRole] = useState('')
    const [profileAgreement, setProfileAgreement] = useState('')

    const [fullNameError, setFullNameError] = useState('')
    const [emailError, setEmailError] = useState('')
    const [mobilePhoneError, setMobilePhoneError] = useState('')
    const [currentAddressError, setCurrentAddressError] = useState('')
    const [profileAgreementError, setProfileAgreementError] = useState('')

    const [isPasswordVisible, setPasswordVisible] = useState(false)
    const [isPasswordConfirmVisible, setPasswordConfirmVisible] = useState(false)
    const [isLoading, setLoading] = useState(false)
    const [errorMsg, setErrorMsg] = useState(null)

    const [isPasswordFocused, setPasswordFocused] = useState(false)
    const [isPasswordConfirmFocused, setPasswordConfirmFocused] = useState(false)
    
    const [password, setPassword] = useState('')
    const [passwordConfirm, setPasswordConfirm] = useState('')

    const [profileData, setProfileData] = useState({})
    // const [profileData, setProfileData] = useState({
    //     full_name: "Theodore",
    //     email: "theodore@gmail.com",
    //     landline_number: "",
    //     phone_number: "62812345",
    //     address: "Jakarta",
    //     address_region: "Bali",
    //     address_locality: "Denpasar"
    // })
    // For data testing

    const fetchData = async () =>{
    let memberModel = new Member()

    try{
    let result = await memberModel.getMemberActivation(activation_token, hpi_id)

    console.log("rslt", result)

    setFullName(result.full_name)
    setEmail(result.email)
    setHomePhone(result.landline_number)
    setMobilePhone(result.phone_number)
    setCurrentAddress(result.address)
    setProvince(result.address_region)
    setCity(result.address_locality)
    setRole(result.role)
    setProfileData(result)

    // setFullName(profileData.full_name)
    // setEmail(profileData.email)
    // setHomePhone(profileData.landline_number)
    // setMobilePhone(profileData.phone_number)
    // setCurrentAddress(profileData.address)
    // setProvince(profileData.address_region)
    // setCity(profileData.address_locality)
    // For data testing

    } catch (e) {
    console.log('ERROR' + e)
    }
    }

    useEffect(() => {
        fetchData()
    }, [])

    useEffect(() => {
        // console.log("Provinces is: ", provinces);
        initProvinceCity()
      }, [provinces]);
    
    useEffect(() => {
    // console.log("Address locality is: ", profileData.address_locality)
    console.log("Address locality is: ", city)
    // }, [profileData.address_locality]);
    }, [city]);



    const onKeyPress = (event) => {
        if (event.key === 'Enter') {
            // attemptRegister()
        }
    }

    function initProvinceCity(){
        let provinceCheck = provinces.some(p => province === p.label)
        if(provinceCheck){
            let provinceId = provinces.find(p => province === p.label)
            onProvinceChange(provinceId.value)
        }
    }

    function initProvince(){
        let provinceCheck = provinces.some(p => province === p.label)
        if(provinceCheck){
            let provincee = provinces.find(p => province === p.label)
            return provincee
        }
        else{
            if(province !== null){//If null return label nothing
            return {label: province}
        }
    }
    }


    function initCity(){
        let cityCheck = cities.some(c => c.label.includes(city))
        if(cityCheck){
            let cityId = cities.find(c => c.label.includes(city))
            return cityId
        }
        else{
            if(city !== null){//If null return label nothing
                return {label: city}
            }
        }
    }

    const onProvinceChange = (target) => {
        setCities([])

        console.log('onProvinceChange has been initiated!')
        console.log(target)
        console.log(city)
        console.log([...Cities.filter((city) => city.province_id === target)])

        setCities([
            ...Cities.filter((city) => city.province_id === target).map((value) => ({
                label: value.type? (value.type + ' ' + value.city_name) : value.city_name,
                value: value.city_id,
                province_id: value.province_id,
            })),
        ])
    }


    const saveProfile = async () => {
        setErrorMsg(null)

        if (fullName.length === 0) {
            Swal.fire('', 'Harap isi Nama Lengkap', 'error')
            return
        }

        if (email.length === 0) {
            Swal.fire('', 'Harap isi Surel', 'error')
            return
        }

        if (mobilePhone.length === 0) {
            Swal.fire('', 'Harap Isi Nomor Telepon Seluler', 'error')
            return
        }

        if (currentAddress.length === 0) {
            Swal.fire('', 'Harap isi Alamat Lengkap', 'error')
            return
        }
        if (password.length === 0) {
            Swal.fire('', 'Harap isi Kata Sandi', 'error')
            return
        }

        if (passwordConfirm.length === 0) {
            Swal.fire('', 'Harap isi Konfirmasi Kata Sandi', 'error')
            return
        }

        if (password !== passwordConfirm) {
            Swal.fire('', 'Kata Sandi dan Konformasi Kata Sandi Tidak Sesuai', 'error')
            return
        }

        if (province.length === 0) {
            Swal.fire('', 'Harap pilih Provinsi', 'error')
            return
        }

        if (city.length === 0) {
            Swal.fire('', 'Harap pilih Kota/Kabupaten', 'error')
            return
        }
        if (role === 'PENUH' && profileAgreement.length === 0) {
            Swal.fire('', 'Harap memilih Kebersediaan untuk menampilkan profil', 'error')
            return
        }

      
        let model = new Member()

        setLoading(true)

        try {
            let payload = {
                token: activation_token,
                email,
                full_name: fullName,
                address: currentAddress,
                phone_number: mobilePhone,
                landline_number: homePhone,
                new_password: password,
                address_region: province,
                address_locality: city,
                is_public: role === 'MUDA' ? false : profileAgreement,
            }
            
            console.log(payload)
            let result = await model.finishMigration(payload)

            localStorage.setItem('user', JSON.stringify(result))
            localStorage.setItem(
                'expired_at',
                new Date(new Date().getTime() + 60 * 60 * 27 * 1000),
            )
            setLoading(false)

            console.log('hubla', result)

            Swal.fire({
                // title: 'Selamat Datang',
                text: 'Selamat datang di SIHAPEI',
                // text: 'Profil akun anda berhasil disimpan dan kata sandi baru bisa digunakan untuk login',
                icon: 'info',
                confirmButtonText: 'OKE',
            }).then((result) => {
                props.history.push('/member')
            })

            setLoading(false)
        } catch (e) {

            console.log(e)
            await Swal.fire({
                title: 'Terjadi Kesalahan',
                text: e.error_message,
                icon: 'error',
                confirmButtonText: 'OKE',
            })
            // setErrorMsg(e.error_message)

            setLoading(false)
        }
        // }

    }

    useEffect(() => {
        setProvinces(
            Provinces.map((value) => ({ label: value.province, value: value.province_id })),
        )
    }, [])

    useEffect(() => {
        // getAllLanguages()
        // getAllSpecialization()
        // console.log("Address locality is: ", profileData.address_locality)
    }, [])

    useEffect(()=>{
        if(errorMsg) window.scrollTo({top:0, behavior:'smooth'})
    },[errorMsg])


    const DropdownIndicator = (props) => {
        return (
            components.DropdownIndicator && (
                <components.DropdownIndicator {...props}>
                    <FaAngleDown color={'grey'} />
                </components.DropdownIndicator>
            )
        )
    }

    return (
        <Container style={{ minHeight: '100vh', backgroundColor: 'white', marginTop: 30 }}>
            <div
                style={{
                    marginTop: 'auto',
                    display: 'block',
                    backgroundColor: 'white',
                    borderTopRightRadius: 5,
                    borderBottomRightRadius: 5,
                    position: 'relative',
                }}
                className="text-center"
            >
                <h1 className="my-5">
                    Halo dan selamat datang di Direktori Penerjemah & Juru Bahasa Indonesia!
                </h1>
                <h5 className="my-5 w-75 container" style={{ color: '#9B9B9B' }}>
                    Harap membuat kata sandi untuk mengaktifkan akun Anda.
                </h5>

                <Row style={{ marginTop: 20 }}>
                    <Col xl={5} lg={5} md={6} xs={12} className={'m-auto'}>
                        {errorMsg ? (
                            <Row
                                style={{
                                    backgroundColor: '#ffc9cf',
                                    color: '#e3192d',
                                    alignItems: 'center',
                                    border: '1px solid #d5bab9',
                                    paddingRight: 10,
                                    paddingTop: 7,
                                    paddingBottom: 7,
                                    marginBottom: 20,
                                    borderRadius: 5,
                                }}
                            >
                                <Col xs={1}>
                                    <MdErrorOutline size={27} color={'#a25b5d'} />
                                </Col>
                                <Col
                                    style={{
                                        color: '#a25b5d',
                                        fontFamily: 'Signika',
                                        fontWeight: '600',
                                        marginLeft: 5,
                                    }}
                                >
                                    {errorMsg}
                                </Col>
                            </Row>
                        ) : null}

                        <div
                            style={{
                                fontFamily: 'Signika',
                                fontWeight: '600',
                                fontSize: '0.75em',
                                color: '#8e8e8e',
                                marginBottom: 7,
                                textAlign: "left"
                            }}
                            className="mt-3"
                        >
                            HPI ID
                        </div>
                        <InputGroup>
                            <InputGroup.Prepend>
                                <InputGroup.Text
                                    style={{
                                        borderWidth: 0,
                                        display: 'flex',
                                        alignItems: 'center',
                                        backgroundColor: '#e9f3f4',
                                    }}
                                >
                                    <MdPerson size={20} />
                                </InputGroup.Text>

                                <InputGroup.Text
                                    style={{
                                        borderWidth: 0,
                                        display: 'flex',
                                        alignItems: 'center',
                                        paddingLeft: 0,
                                        paddingRight: 0,
                                    }}
                                >
                                    <div
                                        style={{
                                            height: '75%',
                                            width: 2,
                                            backgroundColor: '#9ca4a6',
                                        }}
                                    />
                                </InputGroup.Text>
                            </InputGroup.Prepend>

                            <FormControl
                                placeholder="HPI ID"
                                style={{
                                    borderWidth: 0,
                                    backgroundColor: '#d3d7de',
                                    outline: 'none',
                                    boxShadow: 'none',
                                    fontFamily: 'Signika',
                                    fontWeight: '600',
                                }}
                                disabled
                                value={profileData?.hpi_id}
                            />
                        </InputGroup>



                        <div
                            style={{
                                fontFamily: 'Signika',
                                fontWeight: '600',
                                fontSize: '0.75em',
                                color: '#8e8e8e',
                                marginBottom: 7,
                                textAlign: "left"
                            }}
                            className="mt-3"
                        >
                            NAMA LENGKAP
                        </div>
                        <InputGroup

                        >
                            <InputGroup.Prepend>
                                <InputGroup.Text
                                    style={{
                                        borderWidth: 0,
                                        display: 'flex',
                                        alignItems: 'center',
                                        backgroundColor: '#e9f3f4',
                                    }}
                                >
                                    <MdPerson size={20} />
                                </InputGroup.Text>

                                <InputGroup.Text
                                    style={{
                                        borderWidth: 0,
                                        display: 'flex',
                                        alignItems: 'center',
                                        paddingLeft: 0,
                                        paddingRight: 0,
                                    }}
                                >
                                    <div
                                        style={{
                                            height: '75%',
                                            width: 2,
                                            backgroundColor: '#9ca4a6',
                                        }}
                                    />
                                </InputGroup.Text>
                            </InputGroup.Prepend>

                            <FormControl
                                placeholder="Nama Lengkap"
                                style={{
                                    borderWidth: 0,
                                    backgroundColor: '#d3d7de',
                                    outline: 'none',
                                    boxShadow: 'none',
                                    fontFamily: 'Signika',
                                    fontWeight: '600',
                                }}
                                // value={profileData?.full_name}
                                value={fullName}
                                onChange={(event) => {
                                    setFullName(event.target.value)
                                    setFullNameError((event.target.value).length === 0)
                                }}
                                disabled
                            />
                        </InputGroup>

                        <div
                            style={{
                                fontFamily: 'Signika',
                                fontWeight: '600',
                                fontSize: '0.75em',
                                color: '#8e8e8e',
                                marginBottom: 7,
                                textAlign: "left"
                            }}
                            className="mt-3"
                        >
                            SUREL
                        </div>
                        <InputGroup>
                            <InputGroup.Prepend>
                                <InputGroup.Text
                                    style={{
                                        borderWidth: 0,
                                        display: 'flex',
                                        alignItems: 'center',
                                        backgroundColor: '#e9f3f4',
                                    }}
                                >
                                    <MdEmail size={20} />
                                </InputGroup.Text>

                                <InputGroup.Text
                                    style={{
                                        borderWidth: 0,
                                        display: 'flex',
                                        alignItems: 'center',
                                        paddingLeft: 0,
                                        paddingRight: 0,
                                    }}
                                >
                                    <div
                                        style={{
                                            height: '75%',
                                            width: 2,
                                            backgroundColor: '#9ca4a6',
                                        }}
                                    />
                                </InputGroup.Text>
                            </InputGroup.Prepend>

                            <FormControl
                                placeholder="Surel"
                                onChange={(event) => {
                                    setEmail(event.target.value)
                                    setEmailError((event.target.value).length === 0)
                                }}
                                style={{
                                    borderWidth: 0,
                                    backgroundColor: '#e9f3f4',
                                    outline: 'none',
                                    boxShadow: 'none',
                                    fontFamily: 'Signika',
                                    fontWeight: '600',
                                }}
                                value={email}
                            />
                        </InputGroup>

                        <div
                            style={{
                                fontFamily: 'Signika',
                                fontWeight: '600',
                                fontSize: '0.75em',
                                color: '#8e8e8e',
                                marginBottom: 7,
                                textAlign: "left"
                            }}
                            className="mt-3"
                        >
                            PROVINSI
                        </div>

                        <InputGroup>
                        <InputGroup.Prepend>
                            <InputGroup.Text
                                style={{
                                    borderWidth: 0,
                                    display: 'flex',
                                    alignItems: 'left',
                                    backgroundColor: '#e9f3f4',
                                }}
                            >
                                <MdLocationOn size={20} />
                            </InputGroup.Text>
                        </InputGroup.Prepend>

                        <div style={{ flex: 1, textAlign: "left"}}>
                            <Select
                                placeholder={'Pilih Provinsi'}
                                options={provinces}
                                onChange={(e) => {
                                    setProvince(e.label)
                                    setProvinceError(e.value === null)
                                    onProvinceChange(e.value);
                                    // console.log(province);
                                }}
                                styles={{
                                    option: (provided, state) => ({
                                        ...provided,
                                        cursor: 'pointer',
                                    }),
                                    control: (provided) => ({
                                        ...provided,
                                        borderWidth: 0,
                                        backgroundColor: '#e9f3f4',
                                        fontFamily: 'Signika',
                                        fontWeight: '600',
                                        color: '#8e8e8e',
                                    }),
                                }}
                                value={initProvince()}
                                // value={province == profileData?.address_region ? {label: profileData?.address_region} : provinces.find(p => province === p.value)}
                                // defaultValue={province}
                                components={{
                                    DropdownIndicator,
                                    IndicatorSeparator: () => null,
                                }}
                            />
                        </div>
                    </InputGroup>

                    {
                        // (cities && cities.length > 0) &&
                        <>
                            <div style={{
                                fontFamily: 'Signika',
                                fontWeight: '600',
                                fontSize: '0.75em',
                                color: '#8e8e8e',
                                marginBottom: 7,
                                textAlign: "left"
                            }}
                            className="mt-3">KOTA / KABUPATEN</div>
                            <InputGroup>
                                <InputGroup.Prepend>
                                    <InputGroup.Text
                                        style={{
                                            borderWidth: 0,
                                            display: 'flex',
                                            alignItems: 'center',
                                            backgroundColor: '#e9f3f4',
                                        }}
                                    >
                                        <MdLocationOn size={20} />
                                    </InputGroup.Text>
                                </InputGroup.Prepend>


                                <div style={{ flex: 1, textAlign: "left"}}>
                                    <Select
                                        placeholder={'Pilih Kota/Kabupaten'}
                                        options={cities}
                                        // value={cities.some(c => profileData?.address_locality === c.city_name) ? cities.find(c => city === c.city_name) : {label: profileData?.address_locality}}
                                        value={initCity()}
                                        onChange={(e) => {
                                            console.log(e)
                                            setCity(e.label)
                                            setCityError(e.value === null)
                                        }}
                                        styles={{
                                            option: (provided, state) => ({
                                                ...provided,
                                                cursor: 'pointer',
                                            }),
                                            control: (provided) => ({
                                                ...provided,
                                                borderWidth: 0,
                                                backgroundColor: '#e9f3f4',
                                                fontFamily: 'Signika',
                                                fontWeight: '600',
                                                color: '#8e8e8e',
                                            }),
                                        }}
                                        components={{
                                            DropdownIndicator,
                                            IndicatorSeparator: () => null,
                                        }}
                                    />
                                </div>

                            </InputGroup>

                        </>
                    }
                        
                        <div
                            style={{
                                fontFamily: 'Signika',
                                fontWeight: '600',
                                fontSize: '0.75em',
                                color: '#8e8e8e',
                                marginBottom: 7,
                                textAlign: "left"
                            }}
                            className="mt-3"
                        >
                            Alamat tempat tinggal saat ini
                        </div>
                        <InputGroup>
                            <InputGroup.Prepend>
                                <InputGroup.Text
                                    style={{
                                        borderWidth: 0,
                                        display: 'flex',
                                        alignItems: 'center',
                                        backgroundColor: '#e9f3f4',
                                    }}
                                >
                                    <MdEmail size={20} />
                                </InputGroup.Text>

                                <InputGroup.Text
                                    style={{
                                        borderWidth: 0,
                                        display: 'flex',
                                        alignItems: 'center',
                                        paddingLeft: 0,
                                        paddingRight: 0,
                                    }}
                                >
                                    <div
                                        style={{
                                            height: '75%',
                                            width: 2,
                                            backgroundColor: '#9ca4a6',
                                        }}
                                    />
                                </InputGroup.Text>
                            </InputGroup.Prepend>

                            <FormControl
                                placeholder="Ketik perincian secara lengkap"
                                onChange={(event) => {
                                    setCurrentAddress(event.target.value)
                                    setCurrentAddressError(event.target.value.length === 0)
                                }}
                                style={{
                                    borderWidth: 0,
                                    backgroundColor: '#e9f3f4',
                                    outline: 'none',
                                    boxShadow: 'none',
                                    fontFamily: 'Signika',
                                    fontWeight: '600',
                                }}
                                disabled={false}
                                readOnly={false}
                                value={currentAddress}
                            />
                        </InputGroup>

                        <div
                            style={{
                                fontFamily: 'Signika',
                                fontWeight: '600',
                                fontSize: '0.75em',
                                color: '#8e8e8e',
                                marginBottom: 7,
                                textAlign: "left"
                            }}
                            className="mt-3"
                        >
                            NOMOR TELEPON
                        </div>
                        <InputGroup>
                        <PhoneInput
                                placeholder="Phone Number"
                                className="form-control-custom"
                                countryCodeEditable={false}
                                autoFormat={false}
                                country={'id'}
                                preferredCountries={['id']}
                                preserveOrder={['preferredCountries']}
                                disableDropdown={false}
                                value={mobilePhone} onChange={(e) => setMobilePhone(e)}
                                // onChange={(e) => setMobilePhone(e)}
                                    inputStyle={{
                                        borderWidth: "0",
                                        backgroundColor: "#e9f3f4",
                                        outline: "none",
                                        boxShadow: "none",
                                        fontFamily: "Signika",
                                        fontWeight: "600",
                                        
                                    }} 
                                buttonStyle={{backgroundColor: "lightGray", /* backgroundClip: 'border-box', borderRadius: 5 */}}/>
                    </InputGroup>

                    <div
                            style={{
                                fontFamily: 'Signika',
                                fontWeight: '600',
                                fontSize: '0.75em',
                                color: '#8e8e8e',
                                marginBottom: 7,
                                textAlign: "left"
                            }}
                            className="mt-3"
                        >
                            NOMOR TELEPON KEDUA (OPSIONAL)
                        </div>
                        <InputGroup>
                        <PhoneInput
                                placeholder="Phone Number(Opsional)"
                                className="form-control-custom"
                                countryCodeEditable={false}
                                autoFormat={false}
                                country={'id'}
                                preferredCountries={['id']}
                                preserveOrder={['preferredCountries']}
                                disableDropdown={false}
                                value={homePhone} onChange={(e) => setHomePhone(e)}
                                // onChange={(e) => setMobilePhone(e)}
                                    inputStyle={{
                                        borderWidth: "0",
                                        backgroundColor: "#e9f3f4",
                                        outline: "none",
                                        boxShadow: "none",
                                        fontFamily: "Signika",
                                        fontWeight: "600",
                                        
                                    }} 
                                buttonStyle={{backgroundColor: "lightGray", /* backgroundClip: 'border-box', borderRadius: 5 */}}/>
                    </InputGroup>

                    {role == "PENUH" ? (
                    <div style={{
                        textAlign: "left"
                    }}>
                        <div className="mt-3 register-form-label">
                            PERNYATAAN KEBERSEDIAAN UNTUK MENAMPILKAN PROFIL DI DIREKTORI PENERJEMAH DAN JURU BAHASA
                        </div>
                        
                        <div style={{ display: 'grid' }}>
                                <div>
                                    <input
                                        name="profileAgreement"
                                        type="radio"
                                        value="true"
                                        onClick={() => {
                                            setProfileAgreement(true)
                                            setProfileAgreementError(false)
                                        }}
                                    />{' '}
                                    Saya bersedia
                                </div>
                                <div>
                                    <input
                                        name="profileAgreement"
                                        type="radio"
                                        value="false"
                                        onClick={() => {
                                            setProfileAgreement(false)
                                            setProfileAgreementError(false)
                                        }}
                                    />{' '}
                                    Saya tidak bersedia
                                </div>
                            </div>
                        </div>
                        ) : null}

                        <div
                            style={{
                                fontFamily: 'Signika',
                                fontWeight: '600',
                                fontSize: '0.75em',
                                color: '#8e8e8e',
                                marginBottom: 7,
                                marginTop: 20,
                                textAlign: "left"
                            }}
                        >
                            KATA SANDI
                        </div>
                        <InputGroup
                            style={{
                                backgroundColor: '#e9f3f4',
                                boxShadow: isPasswordFocused ? '0 0 10px #9ecaed' : 'none',
                                border: isPasswordFocused ? '1px solid  #9ecaed' : 'none',
                                borderRadius: 5,
                            }}
                        >
                            <InputGroup.Prepend>
                                <InputGroup.Text
                                    style={{
                                        borderWidth: 0,
                                        display: 'flex',
                                        alignItems: 'center',
                                        backgroundColor: '#e9f3f4',
                                    }}
                                >
                                    <MdLock size={20} />
                                </InputGroup.Text>

                                <InputGroup.Text
                                    style={{
                                        borderWidth: 0,
                                        display: 'flex',
                                        alignItems: 'center',
                                        paddingLeft: 0,
                                        paddingRight: 0,
                                    }}
                                >
                                    <div
                                        style={{
                                            height: '75%',
                                            width: 2,
                                            backgroundColor: '#9ca4a6',
                                        }}
                                    />
                                </InputGroup.Text>
                            </InputGroup.Prepend>

                            <FormControl
                                placeholder="Kata Sandi"
                                type={isPasswordVisible ? 'text' : 'password'}
                                style={{
                                    borderWidth: 0,
                                    backgroundColor: '#e9f3f4',
                                    outline: 'none',
                                    boxShadow: 'none',
                                    fontFamily: 'Signika',
                                    fontWeight: '600',
                                }}
                                onChange={(event) => setPassword(event.target.value)}
                                onFocus={() => setPasswordFocused(true)}
                                onBlur={() => setPasswordFocused(false)}
                                onKeyPress={onKeyPress}
                            />

                            <InputGroup.Prepend
                                style={{
                                    cursor: 'pointer',
                                    display: 'flex',
                                    alignItems: 'center',
                                    paddingRight: 10,
                                    marginRight: 10,
                                    backgroundColor: '#e9f3f4',
                                }}
                                onClick={() => setPasswordVisible(!isPasswordVisible)}
                            >
                                {isPasswordVisible ? <IoMdEye size={20} /> : <IoMdEyeOff size={20} />}
                            </InputGroup.Prepend>
                        </InputGroup>

                        <div
                            style={{
                                fontFamily: 'Signika',
                                fontWeight: '600',
                                fontSize: '0.75em',
                                color: '#8e8e8e',
                                marginBottom: 7,
                                marginTop: 20,
                                textAlign: "left"
                            }}
                        >
                            KONFIRMASI KATA SANDI
                        </div>
                        <InputGroup
                            style={{
                                backgroundColor: '#e9f3f4',
                                boxShadow: isPasswordConfirmFocused ? '0 0 10px #9ecaed' : 'none',
                                border: isPasswordConfirmFocused ? '1px solid  #9ecaed' : 'none',
                                borderRadius: 5,
                            }}
                        >
                            <InputGroup.Prepend>
                                <InputGroup.Text
                                    style={{
                                        borderWidth: 0,
                                        display: 'flex',
                                        alignItems: 'center',
                                        backgroundColor: '#e9f3f4',
                                    }}
                                >
                                    <MdLock size={20} />
                                </InputGroup.Text>

                                <InputGroup.Text
                                    style={{
                                        borderWidth: 0,
                                        display: 'flex',
                                        alignItems: 'center',
                                        paddingLeft: 0,
                                        paddingRight: 0,
                                    }}
                                >
                                    <div
                                        style={{
                                            height: '75%',
                                            width: 2,
                                            backgroundColor: '#9ca4a6',
                                        }}
                                    />
                                </InputGroup.Text>
                            </InputGroup.Prepend>

                            <FormControl
                                placeholder="Konfirmasi Kata Sandi"
                                type={isPasswordConfirmVisible ? 'text' : 'password'}
                                style={{
                                    borderWidth: 0,
                                    backgroundColor: '#e9f3f4',
                                    outline: 'none',
                                    boxShadow: 'none',
                                    fontFamily: 'Signika',
                                    fontWeight: '600',
                                }}
                                onChange={(event) => setPasswordConfirm(event.target.value)}
                                onFocus={() => setPasswordConfirmFocused(true)}
                                onBlur={() => setPasswordConfirmFocused(false)}
                                onKeyPress={onKeyPress}
                            />

                            <InputGroup.Prepend
                                style={{
                                    cursor: 'pointer',
                                    display: 'flex',
                                    alignItems: 'center',
                                    paddingRight: 10,
                                    marginRight: 10,
                                    backgroundColor: '#e9f3f4',
                                }}
                                onClick={() => setPasswordConfirmVisible(!isPasswordConfirmVisible)}
                            >
                                {isPasswordConfirmVisible ? (
                                    <IoMdEye size={20} />
                                ) : (
                                    <IoMdEyeOff size={20} />
                                )}
                            </InputGroup.Prepend>
                        </InputGroup>
                    </Col>
                </Row>

                <br></br>

                <Button
                    style={{
                        fontFamily: 'Signika',
                        fontWeight: 'bold',
                        paddingTop: 10,
                        paddingBottom: 10,
                        width: '100%',
                        backgroundColor: Palette.PRIMARY,
                        borderColor: Palette.PRIMARY,
                        'border-radius': '20px',
                    }}
                    onClick={() => saveProfile()}
                    className="col-md-5"
                >
                    {isLoading ? (
                                <Spinner
                                    animation="border"
                                    size={'sm'}
                                    style={{
                                        color: 'white',
                                    }}
                                />
                            ) : (
                                "Simpan"
                            )}
                    

                </Button>

            </div>
        </Container>
    )
}

export default WelcomeMigrationPage
