import React, { useState } from 'react'
import { useTable, useSortBy, useGlobalFilter, usePagination } from 'react-table'
import { BsArrowDown, BsArrowUp, FaChevronLeft, FaChevronRight } from 'react-icons/all'
import { Table, Button } from 'react-bootstrap'
import { Input, Select } from '@material-ui/core'
import MenuItem from '@material-ui/core/MenuItem'

import CustomButton from './CustomButton'
import Palette from '../Palette'

//Created using guide from: https://blog.logrocket.com/complete-guide-building-smart-data-table-react/ at this chapter: Building a smart table UI using React
export default function MobTable({ columns, data, interactions, interactionsLabelStyles }) {
    const [filterInput, setFilterInput] = useState('')

    //useTable instance content
    const {
        getTableProps,
        getTableBodyProps,
        headers,
        prepareRow,
        setGlobalFilter,
        pageOptions,
        page,
        state: { pageIndex, pageSize },
        gotoPage,
        previousPage,
        nextPage,
        setPageSize,
        canPreviousPage,
        canNextPage,
    } = useTable(
        {
            columns,
            data,
            initialState: {
                pageSize: 5,
                pageIndex: 0,
            },
        },
        useGlobalFilter, //https://github.com/tannerlinsley/react-table/blob/master/docs/api/useGlobalFilter.md
        useSortBy, //https://github.com/tannerlinsley/react-table/blob/master/docs/api/useSortBy.md,
        usePagination, //https://github.com/tannerlinsley/react-table/blob/master/docs/api/usePagination.md
    )

    return (
        <div>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                }}
            >
                <span
                    style={{
                        marginRight: '1em',
                    }}
                >
                    Cari :
                </span>
                <Input
                    value={filterInput}
                    onChange={(e) => {
                        setFilterInput(e.target.value)
                        setGlobalFilter(e.target.value)
                    }}
                    inputProps={{
                        style: {
                            // fontFamily: 'Poppins',
                            textAlign: 'right',
                        },
                    }}
                    placeholder={'Masukan Kata Pencarian'}
                />
            </div>
            <Table striped hover {...getTableProps} style={{ width: '100%' }}>
                {headers.map((column) => {
                    let icon = column.isSorted ? (
                        column.isSortedDesc ? (
                            <BsArrowDown />
                        ) : (
                            <BsArrowUp />
                        )
                    ) : (
                        <></>
                    )
                    return (
                        <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                            {column.render('Header')} {icon}
                        </th>
                    )
                })}
                {interactions && <th style={interactionsLabelStyles}>
                Opsi
                        </th>}
                <tbody {...getTableBodyProps()}>
                    {page.map((row, i) => {
                        // This line is necessary to prepare the rows and get the row props from react-table dynamically
                        prepareRow(row)

                        //getting the original instance of ob ject
                        let rowInfo = row.original
                        // Each row can be rendered directly as a string using the react-table render method
                        return (
                            <tr {...row.getRowProps()}>
                                {row.cells.map((cell) => {
                                    return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                })}
                                <td>
                                    {interactions &&
                                        interactions.map((interaction, i) => {
                                            if (
                                                !interaction.condition ||
                                                interaction.condition(rowInfo)
                                            ) {
                                                return (
                                                    <Button
                                                        style={{
                                                            width : "100%",
                                                            marginBottom : '5px'
                                                            // marginLeft: '5px',
                                                            // marginRight: '5px',
                                                        }}
                                                        key={`interact-button-${i}`}
                                                        onClick={() => {
                                                            interaction.action(rowInfo)
                                                        }}
                                                    >
                                                        {interaction.name}
                                                    </Button>
                                                )
                                            } else {
                                                return <></>
                                            }
                                        })}
                                </td>
                            </tr>
                        )
                    })}
                </tbody>
            </Table>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                }}
            >
                {/**https://github.com/tannerlinsley/react-table/tree/master/examples/pagination**/}
                Halaman
                <CustomButton onClick={() => previousPage()} disabled={!canPreviousPage}>
                    <span
                        style={{
                            color: canPreviousPage ? Palette.PRIMARY : 'lightGray',
                        }}
                    >
                        <FaChevronLeft />
                    </span>
                </CustomButton>
                <span>
                    {pageIndex + 1} of {pageOptions.length}
                </span>
                <CustomButton
                    onClick={() => nextPage()}
                    disabled={!canNextPage}
                    style={{ marginRight: '40px' }}
                >
                    <span
                        style={{
                            color: canNextPage ? Palette.PRIMARY : 'lightGray',
                        }}
                    >
                        <FaChevronRight />
                    </span>
                </CustomButton>
                <span>Buka Halaman: </span>
                <Input
                    type="number"
                    defaultValue={pageIndex + 1 || 1}
                    style={{ width: '50px', marginRight: '40px' }}
                    onChange={(e) => {
                        const page = e.target.value ? Number(e.target.value) - 1 : 0
                        gotoPage(page)
                    }}
                />
                <span
                    style={{
                        marginRight: '1em',
                    }}
                >
                    Data per Halaman :
                </span>
                <Select
                    value={pageSize}
                    onChange={(e) => {
                        setPageSize(Number(e.target.value))
                    }}
                >
                    {[5, 10, 50, 100].map((pageSize) => (
                        <MenuItem value={pageSize} key={pageSize}>
                            {pageSize}
                        </MenuItem>
                    ))}
                </Select>
            </div>
        </div>
    )
}
