import React, { useEffect, useState } from 'react'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Container from 'react-bootstrap/Container'
import { useParams, useHistory } from 'react-router-dom'
import parse from 'html-react-parser'
import moment from 'moment'
import Content from '../models/Content'

export default function ContentDetailPage(props) {
    const { id } = useParams()

    const history = useHistory()

    let content = new Content()

    const [contentDetail, setContentDetail] = useState(null)

    useEffect(() => {
        getContentData()
    }, [])

    const getContentData = async () => {
        try {
            let contentDetail = await content.getById(id)
            console.log('contentDetail', contentDetail)
            setContentDetail(contentDetail)
        } catch (e) {
            console.error(e)
        }
    }

    return (
        <>
            <Container
                className="shadow-sm"
                style={{
                    backgroundColor: 'white',
                    borderRadius: 10,
                    color: '#363636',
                    marginTop: 30,
                }}
            >
                <Row>
                    <Col className="my-2 h-100">
                        <div className="d-block" onClick={() => history.push('/member/konten')}>
                            <span style={{ color: '#007bff', cursor: 'pointer' }}>
                                {' '}
                                {'<'} Kembali ke konten{' '}
                            </span>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col className="my-2 h-100 text-center">
                        <div className="d-block">
                            <h1>{contentDetail?.title}</h1>
                        </div>
                        <div
                            style={{
                                fontFamily: 'Signika',
                                fontWeight: '600',
                                fontSize: '0.75em',
                                color: '#8e8e8e',
                            }}
                        >
                            {moment(content.created_at).format('dddd, DD MMM YYYY')}
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col className="my-2 h-100">
                        <div className="d-block text-center">
                            {contentDetail?.banner_url && (
                                <img
                                    src={contentDetail?.banner_url}
                                    style={{
                                        maxWidth: '100%',
                                        maxHeight: '100%',
                                    }}
                                    alt={contentDetail?.title}
                                ></img>
                            )}
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col className="my-2 h-100">
                        <div
                            style={{
                                fontWeight: 400,
                                overflowWrap: 'break-word',
                            }}
                            className="d-block overflow"
                        >
                            {contentDetail?.content ? parse(contentDetail?.content) : ''}
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    )
}
