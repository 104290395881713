import ApiRequest from '../util/ApiRequest'

export default class Member {
    login = async (member) => {
        return await ApiRequest.set('v1/member/login', 'POST', member)
    }

    register = async (member) => {
        return await ApiRequest.set('v1/member/register', 'POST', member)
    }

    getMemberById = async (id) => {
        return await ApiRequest.set('v1/member/'+id, 'GET')
    }    
    
    getMemberByHPIId = async (hpi_id) => {
        return await ApiRequest.set('v1/member/hpi_id/'+hpi_id, 'GET')
    }

    getMemberData = async () => {
        return await ApiRequest.set('v1/member/self', 'GET')
    }

    getMemberActivation = async (token, hpi_id) => {
        return await ApiRequest.set('v1/member/activate_page/'+token+'?hpi_id='+hpi_id, 'GET')
    }

    getActiveMembership = async () => {
        return await ApiRequest.set('v1/membership/active', 'GET')
    }

    editMemberData = async (member) => {
        return await ApiRequest.set('v1/member/self', 'PUT', member)
    }

    search = async (query) => {
        return await ApiRequest.set('v1/members/search/fullname_HPID?query='+query, 'GET')
    }

    searchAdvanced = async (query, language_id_from, language_id_to, profession, specializations_id, certification) => {
        return await ApiRequest.set('' +
            `v1/members/search/advanced?query=${query}` +
            (language_id_from ? `&language_id_from=${language_id_from}` : "") +
            (language_id_to ? `&language_id_to=${language_id_to}` : "") +
            (profession ? `&profession=${profession}` : "") +
            (specializations_id ? `&specialization_ids=(${specializations_id})` : "") +
           
            (certification ? `&certification=${certification}` : "")
            ,
            'GET')
    }

    forgotPassword = async (body) => {
        return await ApiRequest.set('v1/member/forgotPassword',"POST", body);
    }

    resetPassword = async (body) => {
        return await ApiRequest.set('v1/member/resetPassword',"POST", body);
    }

    finishMigration = async (body) => {
        return await ApiRequest.set('v1/member/activate_migration',"POST", body);
    }

    changePassword = async (body) => {
        return await ApiRequest.set('v1/member/self/password',"PUT", body);
    }

    upgradeMembership = async (body) => {
        return await ApiRequest.set('v1/member/upgrade_membership',"POST", body);
    }
}
