import React, { useEffect, useState } from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { Link, Route, Switch, Redirect, useLocation } from 'react-router-dom'
import { isMobile } from 'react-device-detect'
import Snackbar from '@material-ui/core/Snackbar'

import MemberShip from '../models/Membership'
import ProfilePage from './ProfilePage'
import ContentPage from './ContentPage'
import InvoicePage from './InvoicePage'
import EditProfilePage from './EditProfilePage'
import EditMembershipPage from './EditMembershipPage'
import EditPasswordPage from './EditPasswordPage'
import RecordingPage from './RecordingPage'
import ContentDetailPage from './ContentDetailPage'
import Palette from "../Palette";
import LoginPage from "./LoginPage";
import Alert from '../components/Alert'
import moment from 'moment'
import Member from '../models/Member'


const nonActiveStyle = {}
const activeStyle = {
    fontWeight: 800,
    color: Palette.PRIMARY
}
export default function MemberPage(props) {

    const location = useLocation()

    const [windowWidth, setWindowWidth] = useState(window.innerWidth)
    const [isMembershipActive, setIsMembershipActive] = useState(true)
    const [showSnackbar, setShowSnackbar] = useState(false)
    const getMembershipData = async () => {
        let membershipModel = new MemberShip()
        try {
            const result = await membershipModel.getMembershipStatus()
            setIsMembershipActive(!!result.active)
        } catch (e) {
            console.log('error ' + e)
        }
    }

    const handleRedirect = () => {
        setShowSnackbar(!isMembershipActive)
    }

    let isLoggedIn = !!localStorage.getItem('user')

    useEffect(() => {
        getMembershipData()
    }, [])


    return (
        <>

            <Container style={{ minHeight: 'calc(100vh - 280px)' }}>
                <Snackbar
                    autoHideDuration={3000}
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                    open={showSnackbar}
                    onClose={() => setShowSnackbar(false)}
                    message="Please activate membership to gain access"
                />

                <Row
                    style={{
                        backgroundColor: 'white',
                        borderRadius: 10,
                        paddingLeft: windowWidth < 768 ? 0 : 50,
                        paddingRight: windowWidth < 768 ? 0 : 50,
                        paddingBottom: 10,
                    }}
                >

                    <Col
                        md={3}
                        className="p-4 position-sticky shadow-sm h-100"
                        style={isMobile ? { top: 0, display: 'none' } : { top: 0 }}
                    >
                        <Link
                            to="/member/akun"
                            style={{
                                textDecoration: 'none',
                                color: 'black', ...(location.pathname.includes("/akun") ? { ...activeStyle } : { ...nonActiveStyle })
                            }}
                            className="p-3"
                        >
                            Akun
                        </Link>
                        <hr></hr>
                        <Link
                            to="/member/membership"
                            style={{
                                textDecoration: 'none',
                                color: 'black', ...(location.pathname.includes("/membership") ? { ...activeStyle } : { ...nonActiveStyle })
                            }}
                            className="p-3"
                        >
                            Keanggotaan
                        </Link>
                        <hr></hr>
                        <Link
                            to="/kegiatan"
                            style={{
                                textDecoration: 'none',
                                color: 'black', ...(location.pathname.includes("/kegiatan") ? { ...activeStyle } : { ...nonActiveStyle })
                            }}
                            className="p-3"
                        >
                            Acara
                        </Link>
                        <hr></hr>
                        <Link
                            to="/member/konten"
                            onClick={() => handleRedirect()}
                            style={{
                                textDecoration: 'none',
                                color: 'black', ...(location.pathname.includes("/konten") ? { ...activeStyle } : { ...nonActiveStyle })
                            }}
                            className="p-3"
                        >
                            Konten
                        </Link>
                        <hr></hr>
                        <Link
                            onClick={() => {
                                localStorage.removeItem('popUpReminder')
                                localStorage.removeItem('user')
                            }}
                            to="/"
                            style={{ textDecoration: 'none', color: 'black', }}
                            className="p-3"
                        >
                            Keluar
                        </Link>
                    </Col>
                    <Col md={9}>

                        <div className={'shadow-sm'}>
                            <Container>
                        

                                <Row>
                                    <Switch>
                                        <Route
                                            path="/member/akun"
                                            exact={true}
                                            render={(props) => {
                                                if (!isLoggedIn) {
                                                    return <Redirect
                                                        to={{
                                                            pathname: '/login'
                                                        }}
                                                    />
                                                }

                                                return <ProfilePage {...props} />
                                            }}
                                        ></Route>
                                        <Route
                                            path="/member/akun/edit"
                                            exact={true}
                                            render={(props) => {
                                                if (!isLoggedIn) {
                                                    return <Redirect
                                                        to={{
                                                            pathname: '/login'
                                                        }}
                                                    />
                                                }

                                                return <EditProfilePage {...props} />
                                            }}
                                        ></Route>
                                        <Route
                                            path="/member/akun/editPassword"
                                            exact={true}
                                            render={(props) => {
                                                if (!isLoggedIn) {
                                                    return <Redirect
                                                        to={{
                                                            pathname: '/login'
                                                        }}
                                                    />
                                                }

                                                return <EditPasswordPage {...props} />
                                            }}
                                        ></Route>
                                        <Route
                                            path="/member/akun/edit-2"
                                            exact={true}
                                            render={(props) => {
                                                if (!isLoggedIn) {
                                                    return <Redirect
                                                        to={{
                                                            pathname: '/login'
                                                        }}
                                                    />
                                                }

                                                return <EditMembershipPage {...props} />
                                            }}
                                        ></Route>
                                        <Route
                                            path="/member/membership"
                                            exact={true}
                                            render={(props) => {
                                                if (!isLoggedIn) {
                                                    return <Redirect
                                                        to={{
                                                            pathname: '/login'
                                                        }}
                                                    />
                                                }

                                                return <InvoicePage {...props} />
                                            }}
                                        ></Route>
                                        <Route
                                            exact={true}
                                            path="/member/rekaman"
                                            component={RecordingPage}
                                        />

                                        <Route
                                            path="/member/konten"
                                            exact={true}
                                            render={(props) =>
                                                isMembershipActive ? (
                                                    <ContentPage {...props} />
                                                ) : (
                                                    <Redirect
                                                        to={{
                                                            pathname: '/member',
                                                            state: { from: props.location },
                                                        }}
                                                    />
                                                )
                                            }
                                        ></Route>
                                        <Route
                                            path="/member/konten/:id"
                                            exact={true}
                                            render={(props) =>
                                                isMembershipActive ? (
                                                    <ContentDetailPage {...props} />
                                                ) : (
                                                    <Redirect
                                                        to={{
                                                            pathname: '/member',
                                                            state: { from: props.location },
                                                        }}
                                                    />
                                                )
                                            }
                                        ></Route>
                                        <Route component={ProfilePage} />
                                    </Switch>
                                </Row>
                            </Container>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>


    )
}
