import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import {IoMdArrowBack, IoMdCalendar, IoMdEye, IoMdEyeOff} from "react-icons/io";
import Palette from "../Palette";
import Col from "react-bootstrap/Col";
import {FormControl, InputGroup} from "react-bootstrap";
import RichTextEditor from "react-rte";
import Select from "react-select";
import IconButton from "@material-ui/core/IconButton";
import {AiOutlineLink, FaDownload, FaMinus, FaPlus, FaTrash} from "react-icons/all";
import {MdAttachMoney, MdCreditCard, MdKeyboard, MdLock} from "react-icons/md";
import Button from "react-bootstrap/Button";
import EditProfileUploadModal from "../components/Modals/EditProfileUploadFileModal";
import EditProfileAddMembershipModal from "../components/Modals/EditProfileAddMembershipModal";
import React, {useState} from "react";
import Swal from "sweetalert2";
import Member from "../models/Member";


function EditPasswordPage(props){
    const [editResume, setEditResume] = useState(() => RichTextEditor.createEmptyValue())
    const [editAvailability, setEditAvailability] = useState('')
    const [editOccupation, setEditOccupation] = useState('')
    const [editEducation, setEditEducation] = useState(() => RichTextEditor.createEmptyValue())
    const [editSpecializationDesc, setEditSpecializationDesc] = useState([])
    const [editLanguagesFrom, setEditLanguagesFrom] = useState(null)
    const [editLanguagesTo, setEditLanguagesTo] = useState(null)
    const [editServices, setEditServices] = useState(() => RichTextEditor.createEmptyValue())
    const [editTranslatorSince, setEditTranslatorSince] = useState('')
    const [editInterpreterSince, setEditInterpreterSince] = useState([])
    const [editPreferredCurrency, setEditPreferredCurrency] = useState('')
    const [editPaymentMethod, setEditPaymentMethod] = useState('')
    const [editSoftware, setEditSoftware] = useState('')
    const [editAboutMe, setEditAboutMe] = useState(() => RichTextEditor.createEmptyValue())
    const [isPublic, setIsPublic] = useState(true)
    const [editFiles, setEditFiles] = useState([])


    const [isEditProfessionFocused, setEditProfessionFocused] = useState(false)
    const [editProfession, setEditProfession] = useState('')

    const [isEditAvailabilityFocused, setEditAvailabilityFocused] = useState(false)
    const [isEditOccupationFocused, setEditOccupationFocused] = useState(false)
    const [isEditSpecializationDescFocused, setEditSpecializationDescFocused] = useState(false)
    const [isEditLanguagesFocused, setEditLanguagesFocused] = useState(false)
    const [isEditTranslatorSinceFocused, setEditTranslatorSinceFocused] = useState(false)
    const [isEditInterpreterSinceFocused, setEditInterpreterSinceFocused] = useState(false)
    const [isEditPreferredCurrencyFocused, setEditPreferredCurrencyFocused] = useState(false)
    const [isEditPaymentMethodFocused, setEditPaymentMethodFocused] = useState(false)
    const [isEditSoftwareFocused, setEditSoftwareFocused] = useState(false)
    const [isPublicFocused, setPublicFocused] = useState(false)
    const [specializationsOptions, setSpecializationsOptions] = useState([])
    const [languagesOptions, setLanguagesOptions] = useState([])
    const [selectedLanguagePair, setSelectedLanguagePair] = useState([])

    const [isUploadingFile, setIsUploadingFile] = useState(false)

    const [isAddingMembership, setIsAddingMembership] = useState(false)
    const [editMembership, setEditMembership] = useState([])

    const [isConfirmPasswordFocused, setConfirmPasswordFocused] = useState(false);
    const [isConfirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
    const [confirmPassword, setConfirmPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [currentPassword, setCurrentPassword] = useState('');
    const [isNewPasswordFocused, setNewPasswordFocused] = useState(false);
    const [isNewPasswordVisible, setNewPasswordVisible] = useState(false);
    const [isCurrentPasswordFocused, setCurrentPasswordFocused] = useState(false);
    const [isCurrentPasswordVisible, setCurrentPasswordVisible] = useState(false);


    const handleBackButton = () => {
        props.history.goBack()
    }

    const onKeyPress = (event) => {
        if (event.key === 'Enter') {
            console.log("entered!")
        }
    }

    let memberModel = new Member()

    const handleSave = async () => {
        console.log({
            resume: editResume && editResume.toString('html'),
            availability: editAvailability,
            occupation: editOccupation,
            education: editEducation && editEducation.toString('html'),
            specialization: editSpecializationDesc.map((spec) => {
                return { label: spec.label, id: spec.value }
            }),
            languages: selectedLanguagePair.map((selectLang) => {
                return {
                    from_id: selectLang.language_from.value,
                    to_id: selectLang.language_to.value,
                }
            }),
            service_offered: editServices && editServices.toString('html'),
            translator_since: editTranslatorSince,
            interpreter_since: editInterpreterSince,
            preferred_currency: editPreferredCurrency,
            payment_method: editPaymentMethod,
            software: editSoftware,
            about_me: editAboutMe && editAboutMe.toString('html'),
            is_public: isPublic,
            files: [editFiles],
        })

        try {
            let result = await memberModel.editMemberData({
                resume: editResume && editResume.toString('html'),
                availability: editAvailability,
                occupation: editOccupation,
                education: editEducation && editEducation.toString('html'),
                specialization: editSpecializationDesc.map((spec) => {
                    return { label: spec.label, id: spec.value }
                }),
                languages: selectedLanguagePair.map((selectLang) => {
                    return {
                        from_id: selectLang.language_from.value,
                        to_id: selectLang.language_to.value,
                    }
                }),
                service_offered: editServices && editServices.toString('html'),
                translator_since: editTranslatorSince,
                interpreter_since: editInterpreterSince,
                preferred_currency: editPreferredCurrency,
                payment_method: editPaymentMethod,
                software: editSoftware,
                about_me: editAboutMe && editAboutMe.toString('html'),
                is_public: isPublic,
                files: editFiles,
                profession: editProfession,
                memberscol : editMembership
            })

            console.log(result)

            Swal.fire({
                title: 'Your profile is saved',
                icon: 'success',
                confirmButtonText: 'OKE',
            })
        } catch (e) {
            Swal.fire({
                title: 'Terjadi Kesalahan',
                icon: 'error',
                confirmButtonText: 'OKE',
            })
            console.log(e)
        }
    }

    const attemptChangePassword = async () => {
        if (confirmPassword.length === 0) {
            await Swal.fire({
                title: 'Konfirmasi Sandi harus diisi.',
                icon: 'error',
                confirmButtonText: 'OKE',
            })
        } else if (newPassword !== confirmPassword) {
            await Swal.fire({
                title: 'Konfirmasi Sandi tidak sesuai.',
                icon: 'error',
                confirmButtonText: 'OKE',
            })
        } else if (currentPassword.length === 0){
            await Swal.fire({
                title: 'Sandi saat ini harus diisi.',
                icon: 'error',
                confirmButtonText: 'OKE',
            })
        }
        else if (newPassword.length === 0){
            await Swal.fire({
                title: 'Sandi baru harus diisi.',
                icon: 'error',
                confirmButtonText: 'OKE',
            })
        }
        else {

            try {
                let model = new Member();
                console.log('liat dong', {
                    currentPassword,
                    new_password: newPassword
                })

                let result = await model.changePassword({
                    password: currentPassword,
                    new_password: newPassword
                })

                console.log("RESULT CHANGE PASSWORD: " + result);

                await Swal.fire({
                    title: 'Sandi Anda sudah berhasil diubah.',
                    icon: 'success',
                    confirmButtonText: 'OKE',
                }).then(() => {
                    window.location = "/member"
                })

                // setResetPasswordModalVisible(false)
                // setLoginShown(true)
                // setErrorMsg('Kata sandi berhasil diperbarui!')
                // setLoading(true)


            } catch (e) {
                await Swal.fire({
                    title: e.code === 'PASSWORD_WRONG' ? 'Masukkan sandi yang benar' : 'An error occured',
                    icon: 'error',
                    confirmButtonText: 'OKE',
                })
            }
        }
        // setErrorMsg(null);
        //
        // if (password.length === 0) {
        //     setErrorMsg('Kata sandi tidak boleh kosong!')
        // } else if (confirmPassword.length === 0) {
        //     setErrorMsg('Konfirmasi kata sandi tidak boleh kosong!')
        // } else if (password !== confirmPassword) {
        //     setErrorMsg('Konfirmasi kata sandi tidak sesuai!')
        // } else {
        //     let model = new Member();
        //
        //     setLoading(true)

            // try {
            //     console.log('liat dong', {
            //         email,
            //         new_password: password,
            //         token: resetPasswordCode
            //     })
            //
            //     let result = await model.resetPassword({
            //         email,
            //         new_password: password,
            //         token: resetPasswordCode
            //     })
            //
            //     setResetPasswordModalVisible(false)
            //     setLoginShown(true)
            //     setErrorMsg('Kata sandi berhasil diperbarui!')
            //     setLoading(true)

        //     } catch (e) {
        //         if (e.code === 'TOKEN TIDAK DITEMUKAN_NOT_FOUND') {
        //             setErrorMsg('Kode verifikasi salah!')
        //         } else {
        //             setErrorMsg('Terjadi kesalahan.')
        //         }
        //         console.log('resetPassword err', e)
        //
        //         setLoading(false)
        //     }

        // try {
        //
        //     let model = new Member();
            //
            //     setLoading(true)
            //
            //
            //         let result = await model.resetPassword({
            //             email,
            //             new_password: password,
            //             token: resetPasswordCode
            //         })
            //
            //         setResetPasswordModalVisible(false)
            //         setLoginShown(true)
            //         setErrorMsg('Kata sandi berhasil diperbarui!')
            //         setLoading(true)


        //     let result = await memberModel.editMemberData({
        //         resume: editResume && editResume.toString('html'),
        //         availability: editAvailability,
        //         occupation: editOccupation,
        //         education: editEducation && editEducation.toString('html'),
        //         specialization: editSpecializationDesc.map((spec) => {
        //             return { label: spec.label, id: spec.value }
        //         }),
        //         languages: selectedLanguagePair.map((selectLang) => {
        //             return {
        //                 from_id: selectLang.language_from.value,
        //                 to_id: selectLang.language_to.value,
        //             }
        //         }),
        //         service_offered: editServices && editServices.toString('html'),
        //         translator_since: editTranslatorSince,
        //         interpreter_since: editInterpreterSince,
        //         preferred_currency: editPreferredCurrency,
        //         payment_method: editPaymentMethod,
        //         software: editSoftware,
        //         about_me: editAboutMe && editAboutMe.toString('html'),
        //         is_public: isPublic,
        //         files: editFiles,
        //         profession: editProfession,
        //         memberscol : editMembership
        //     })
        //
        //     console.log(result)
        //
        //     Swal.fire({
        //         title: 'Your profile is saved',
        //         icon: 'success',
        //         confirmButtonText: 'OKE',
        //     })
        // } catch (e) {
        //     Swal.fire({
        //         title: 'An error occured',
        //         icon: 'error',
        //         confirmButtonText: 'OKE',
        //     })
        //     console.log(e)
        // }
    }

    return (
        <>
            <Container className="text-left">
                <Row>
                    <div
                        onClick={handleBackButton}
                        style={{
                            backgroundColor: 'white',
                            borderColor: 'white',
                            color: 'black',
                            cursor: 'pointer',
                        }}
                        className="p-3"
                    >
                        <IoMdArrowBack size={20} />
                    </div>
                    <div
                        style={{
                            fontFamily: 'Signika',
                            fontWeight: '800',
                            fontSize: '1.75em',
                            color: Palette.PRIMARY,
                        }}
                        className="text-center w-100"
                    >
                        Ganti Sandi
                    </div>
                </Row>

                <Row style={{ marginTop: 20 }}>
                    <Col>
                        <div style={{
                            fontFamily: 'Signika',
                            fontWeight: '600',
                            fontSize: '0.75em',
                            color: '#8e8e8e',
                            marginBottom: 7,
                            marginTop: 20
                        }}>
                            SANDI SAAT INI
                        </div>
                        <InputGroup
                            style={{
                                backgroundColor: '#e9f3f4',
                                boxShadow: isCurrentPasswordFocused ? '0 0 10px #9ecaed' : 'none',
                                border: isCurrentPasswordFocused ? '1px solid  #9ecaed' : 'none',
                                borderRadius: 5
                            }}
                        >
                            <InputGroup.Prepend>
                                <InputGroup.Text style={{
                                    borderWidth: 0,
                                    display: 'flex',
                                    alignItems: 'center',
                                    backgroundColor: '#e9f3f4'
                                }}>
                                    <MdLock size={20}/>
                                </InputGroup.Text>

                                <InputGroup.Text style={{
                                    borderWidth: 0,
                                    display: 'flex',
                                    alignItems: 'center',
                                    paddingLeft: 0,
                                    paddingRight: 0
                                }}>
                                    <div style={{height: '75%', width: 2, backgroundColor: '#9ca4a6'}}/>
                                </InputGroup.Text>

                            </InputGroup.Prepend>

                            <FormControl
                                // onKeyPress={onKeyPress}
                                placeholder="Sandi Saat Ini"
                                type={isCurrentPasswordVisible ? 'text' : 'password'}
                                style={{
                                    borderWidth: 0,
                                    backgroundColor: '#e9f3f4',
                                    outline: 'none',
                                    boxShadow: 'none',
                                    fontFamily: 'Signika',
                                    fontWeight: '600'
                                }}
                                onChange={(event) => setCurrentPassword(event.target.value)}
                                onFocus={() => setCurrentPasswordFocused(true)}
                                onBlur={() => setCurrentPasswordFocused(false)}
                            />

                            <InputGroup.Prepend style={{
                                cursor: 'pointer',
                                display: 'flex',
                                alignItems: 'center',
                                paddingRight: 10,
                                marginRight: 10,
                                backgroundColor: '#e9f3f4'
                            }}
                                                onClick={() => setCurrentPasswordVisible(!isCurrentPasswordVisible)}>
                                {isCurrentPasswordVisible ? <IoMdEye size={20}/> : <IoMdEyeOff size={20}/>}
                            </InputGroup.Prepend>
                        </InputGroup>

                        <div style={{
                            fontFamily: 'Signika',
                            fontWeight: '600',
                            fontSize: '0.75em',
                            color: '#8e8e8e',
                            marginBottom: 7,
                            marginTop: 20
                        }}>
                            SANDI BARU
                        </div>
                        <InputGroup
                            style={{
                                backgroundColor: '#e9f3f4',
                                boxShadow: isNewPasswordFocused ? '0 0 10px #9ecaed' : 'none',
                                border: isNewPasswordFocused ? '1px solid  #9ecaed' : 'none',
                                borderRadius: 5
                            }}
                        >
                            <InputGroup.Prepend>
                                <InputGroup.Text style={{
                                    borderWidth: 0,
                                    display: 'flex',
                                    alignItems: 'center',
                                    backgroundColor: '#e9f3f4'
                                }}>
                                    <MdLock size={20}/>
                                </InputGroup.Text>

                                <InputGroup.Text style={{
                                    borderWidth: 0,
                                    display: 'flex',
                                    alignItems: 'center',
                                    paddingLeft: 0,
                                    paddingRight: 0
                                }}>
                                    <div style={{height: '75%', width: 2, backgroundColor: '#9ca4a6'}}/>
                                </InputGroup.Text>

                            </InputGroup.Prepend>

                            <FormControl
                                onKeyPress={onKeyPress}
                                placeholder="Sandi Baru"
                                type={isNewPasswordVisible ? 'text' : 'password'}
                                style={{
                                    borderWidth: 0,
                                    backgroundColor: '#e9f3f4',
                                    outline: 'none',
                                    boxShadow: 'none',
                                    fontFamily: 'Signika',
                                    fontWeight: '600'
                                }}
                                onChange={(event) => setNewPassword(event.target.value)}
                                onFocus={() => setNewPasswordFocused(true)}
                                onBlur={() => setNewPasswordFocused(false)}
                            />

                            <InputGroup.Prepend style={{
                                cursor: 'pointer',
                                display: 'flex',
                                alignItems: 'center',
                                paddingRight: 10,
                                marginRight: 10,
                                backgroundColor: '#e9f3f4'
                            }}
                                                onClick={() => setNewPasswordVisible(!isNewPasswordVisible)}>
                                {isNewPasswordVisible ? <IoMdEye size={20}/> : <IoMdEyeOff size={20}/>}
                            </InputGroup.Prepend>
                        </InputGroup>

                        <div style={{
                            fontFamily: 'Signika',
                            fontWeight: '600',
                            fontSize: '0.75em',
                            color: '#8e8e8e',
                            marginBottom: 7,
                            marginTop: 20
                        }}>
                            KONFIRMASI SANDI BARU
                        </div>
                        <InputGroup
                            style={{
                                backgroundColor: '#e9f3f4',
                                boxShadow: isConfirmPasswordFocused ? '0 0 10px #9ecaed' : 'none',
                                border: isConfirmPasswordFocused ? '1px solid  #9ecaed' : 'none',
                                borderRadius: 5
                            }}
                        >
                            <InputGroup.Prepend>
                                <InputGroup.Text style={{
                                    borderWidth: 0,
                                    display: 'flex',
                                    alignItems: 'center',
                                    backgroundColor: '#e9f3f4'
                                }}>
                                    <MdLock size={20}/>
                                </InputGroup.Text>

                                <InputGroup.Text style={{
                                    borderWidth: 0,
                                    display: 'flex',
                                    alignItems: 'center',
                                    paddingLeft: 0,
                                    paddingRight: 0
                                }}>
                                    <div style={{height: '75%', width: 2, backgroundColor: '#9ca4a6'}}/>
                                </InputGroup.Text>

                            </InputGroup.Prepend>

                            <FormControl
                                onKeyPress={onKeyPress}
                                placeholder="Konfirmasi Sandi Baru"
                                type={isConfirmPasswordVisible ? 'text' : 'password'}
                                style={{
                                    borderWidth: 0,
                                    backgroundColor: '#e9f3f4',
                                    outline: 'none',
                                    boxShadow: 'none',
                                    fontFamily: 'Signika',
                                    fontWeight: '600'
                                }}
                                onChange={(event) => setConfirmPassword(event.target.value)}
                                onFocus={() => setConfirmPasswordFocused(true)}
                                onBlur={() => setConfirmPasswordFocused(false)}
                            />

                            <InputGroup.Prepend style={{
                                cursor: 'pointer',
                                display: 'flex',
                                alignItems: 'center',
                                paddingRight: 10,
                                marginRight: 10,
                                backgroundColor: '#e9f3f4'
                            }}
                                                onClick={() => setConfirmPasswordVisible(!isConfirmPasswordVisible)}>
                                {isConfirmPasswordVisible ? <IoMdEye size={20}/> : <IoMdEyeOff size={20}/>}
                            </InputGroup.Prepend>
                        </InputGroup>

                        <Row
                            style={{
                                display: 'flex',
                                justifyResumeContent: 'center',
                                marginTop: 25,
                                marginBottom: 20,
                            }}
                        >
                            <Button
                                style={{
                                    fontFamily: 'Signika',
                                    fontWeight: '600',
                                    paddingTop: 10,
                                    paddingBottom: 10,
                                    backgroundColor: Palette.PRIMARY,
                                    borderColor: Palette.PRIMARY,
                                }}
                                className="w-100 text-uppercase"
                                // onClick={handleSave}
                                onClick={attemptChangePassword}
                            >
                                Simpan
                            </Button>
                        </Row>
                    </Col>
                </Row>
            </Container>

            <EditProfileUploadModal
                close={() => setIsUploadingFile(false)}
                show={isUploadingFile}
                onSubmit={(result) => {
                    setEditFiles([...editFiles, result])
                }}
            />

            <EditProfileAddMembershipModal
                close={() => setIsAddingMembership(false)}
                show={isAddingMembership}
                onSubmit={(result) => {
                    setEditMembership([...editMembership, result])
                }}
            />
        </>
    )
}

export default EditPasswordPage