import Container from 'react-bootstrap/Container'
import React, { useMemo, useEffect, useState } from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import MemberShip from '../models/Membership'
import { Link } from 'react-router-dom'
export default function MembershipModal(props) {

    let {data} = props
    
    let formatter = new Intl.NumberFormat(undefined, {
        style: 'currency',
        currency: 'IDR',
    })

    let modelMemberShip = new MemberShip()
    const continuePayment = async () => {
        const result = await modelMemberShip.buy()
        window.location.href = result.transaction.snap_payment_url;
    }
    return (
        <>
            <Modal
                show={props.displayModal}
                size={'md'}
                onHide={props.toogleSelected}
            >
                <Modal.Header closeButton style={{ borderBottom: 'none !important', background: '#333c47' }}>
                    <p style={{ color: '#FFFFFF', fontSize: '1.1em', textAlign: 'center', fontWeight: 'bold' }}></p>
                </Modal.Header>
                <div style={{ display: 'flex', background: 'rgb(242, 242, 242)' }} >
                    <div style={{ flex: 1, padding: '1rem' }}>
                        <div className="invoice-title" style={{ fontWeight: '600' }}>Iuran Tahunan</div>
                    </div>
                    <div style={{
                        flex: 1,
                        padding: '1rem',
                        alignItems: 'flex-end',
                        textAlign: 'right'
                    }}>
                        <div className='text-price' style={{ fontWeight: 600 }}>{data.map((d) => formatter.format(d.price))} <small>/Tahun</small></div>
                    </div>

                </div>
                <Modal.Body>

                    <div style={{ fontSize: '0.8em' }}>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    </div>
                    <hr></hr>
                    <div style={{ fontSize: '0.8em' }} className={'mt-3'}>
                    Dengan menekan tombol Lanjut, Anda menyetujui <Link>Syarat dan Ketentuan kami.</Link>.
                    </div>
                </Modal.Body>


                <Modal.Footer>
                    <Button onClick={() => {
                        continuePayment()
                    }} size="sm">Lanjut</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}
