import { useEffect, useMemo, useState } from "react"
import MemberCerification from '../models/MemberCertification'
import MobTable from '../components/MobTable'
import { Col, Row } from "react-bootstrap"
import moment from "moment"
export default function CertificatePage() {


    const [certificateData, setCertificateData] = useState([])
    const initializeCertificate = async () => {
        try {
            let result = await MemberCerification.selfCertification()
            console.log('isinya', result)
            setCertificateData(result)

        } catch (e) {
        }
    }
    useEffect(() => {
        initializeCertificate()
    }, [])
    const columns = useMemo(() => {
        let cols = [
            {
                Header: <p style={{ fontSize: '0.7em' }}>Nomor Sertifikat</p>,
                accessor: 'certification_number',
                Cell: (cellInfo) => {
                    let value = cellInfo.row.values
                    return <p style={{ fontSize: '0.7em' }}>{value?.certification_number}</p>
                },

            },
            {
                Header: <p style={{ fontSize: '0.7em' }}>Tipe Sertifikat</p>,
                accessor: 'certification_type',
                Cell: (cellInfo) => {
                    let value = cellInfo.row.values
                    return <p style={{ fontSize: '0.7em' }}>{value?.certification_type}</p>
                },

            },
            {
                Header: <p style={{ fontSize: '0.7em' }}>Penyelenggara</p>,
                accessor: 'certification_organization',
                Cell: (cellInfo) => {
                    let value = cellInfo.row.values
                    return <p style={{ fontSize: '0.7em' }}>{value?.certification_organization}</p>
                },

            },
            {
                Header: <p style={{ fontSize: '0.7em' }}>Berlaku Sampai</p>,
                accessor: 'valid_until',
                Cell: (cellInfo) => {
                    let value = cellInfo.row.values
                    if (value.valid_until == null) {
                        return <p style={{ fontSize: '0.7em' }}>-</p>
                    } else {
                        return <p style={{ fontSize: '0.7em' }}>{moment(value?.valid_until).format('DD MMM YYYY')}</p>
                    }
                    
                },

            },

        ]
        return cols
    })
    return (
        <>
            <Row style={{ marginBottom: 15 }}>

                <Col>
                    {
                        certificateData?.length > 0 ? <MobTable columns={columns} data={certificateData}
                            interactionsLabelStyles={{ fontSize: '0.7em' }} /> : 'Anda belum memiliki sertifikat.'
                    }

                </Col>
            </Row>
        </>
    )
}