import React, { useEffect, useState } from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import { isMobile } from 'react-device-detect'
import { IconButton } from '@material-ui/core'
import { FaEdit } from 'react-icons/all'
import EditProfilePictureModal from '../components/Modals/EditProfilePictureModal'
import moment from 'moment'

import Palette from '../Palette'
import profile2 from '../assets/profile2.png'
import Member from '../models/Member'
import profilePicture from '../assets/default_user.png'

import Provinces from "../util/ProvinceData";
import Cities from "../util/CityData";
import TextTranslator from "../util/TextTranslator";

function MembershipPage(componentProps) {
    const [isUpdatingProfilePicture, setIsUpdatingProfilePicture] = useState(false)
    const [allowEdit, setAllowEdit] = useState(false)
    const fetchSelfData = async () => {
        let memberModel = new Member()
        try {
            let result = await memberModel.getMemberData()

            if(result.member.address_region){
                const province = Provinces.find(p => result.member.address_region === p.province_id);
                if(province){
                    result.member.address_region = province.province;
                }
            }

            if(result.member.address_locality){
                const city = Cities.find(c => result.member.address_locality === c.city_id);
                if(city){
                    result.member.address_locality = city.type + ' ' + city.city_name;
                }
            }

            setProfileData(result)
            console.log(result)
        } catch (e) {
            console.log('ERROR' + e)
        }
    }

    useEffect(() => {
        fetchSelfData()
    }, [])

    const [profileData, setProfileData] = useState({})
    const mockProfileData = {
        honorific: 'Bachelor of Engineering',
        fullName: 'Butch Hartman',
        email: 'butch@hartman.id',
        gender: 'M',
        birth_date: '17-08-1945',
        birth_place: 'Indonesia',
        address_street: 'Jl. Medan Merdeka 45',
        address_locality: 'Jakarta Pusat',
        address_region: 'DKI Jakarta',
        address_country: 'Indonesia',
        address_postal: '12930',
        phone_number: '+628172391823',
        profession: 'Translator',
        approval_date: '20-12-2021',
        commencement_date: '21-12-2021',
        entry_date: '23-12-2021',
        membership: 1,
        specialization: ['Accounting', 'Books-Non-Fiction', 'Automotive'],
    }

    const handleClickEditMembership = () => {
        if (componentProps.props.match.path === '/member/akun') {
            componentProps.props.history.push(componentProps.props.match.path + '/edit-2')
        } else {
            componentProps.props.history.push(componentProps.props.match.path + '/akun/edit-2')
        }
    }

    return (
        <div className="text-left">
            <Row
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginTop: 25,
                    marginBottom: 20,
                }}
            >
                <div
                    style={{
                        fontFamily: 'Signika',
                        fontWeight: '800',
                        fontSize: '1.75em',
                        color: Palette.PRIMARY,
                    }}
                    className={`text-center ${isMobile && 'w-100'}`}
                >
                    Keanggotaan
                </div>
                {profileData?.member?.hpi_id && <Button
                    style={{
                        fontFamily: 'Signika',
                        fontWeight: '600',
                        // paddingTop: 10,
                        // paddingBottom: 10,
                        backgroundColor: Palette.PRIMARY,
                        borderColor: Palette.PRIMARY,
                        right: '0',
                    }}
                    className={`${!isMobile && 'position-absolute mr-4'}`}
                    onClick={handleClickEditMembership}
                >
                    Ubah Keanggotaan
                </Button>}
            </Row>

            <Row
                style={{
                    fontFamily: 'Signika',
                    fontWeight: '600',
                    fontSize: '2em',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column',
                }}
            >
                <img
                    src={
                        profileData?.member?.profile_picture_url
                            ? profileData.member.profile_picture_url
                            : profilePicture
                    }
                    style={{
                        width: 130,
                        objectFit: 'contain',
                    }}
                    alt="user"
                />

                <div
                    style={{
                        width: 130,
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'flex-end',
                    }}
                >
                    <IconButton
                        onClick={() => {
                            setIsUpdatingProfilePicture(true)
                        }}
                        style={{
                            marginTop: '-1em',
                            marginRight: '-0.5em',
                            background: 'white',
                            border: Palette.PRIMARY,
                            boxShadow:  '1px 1px 0px rgba(100,100,100,.5)',
                        }}
                    >
                        <FaEdit size={14}/>
                    </IconButton>
                </div>
            </Row>

            <Row style={{ marginTop: 20 }}>
                <Col>
                   
                    <div
                        style={{
                            fontFamily: 'Signika',
                            fontWeight: '600',
                            fontSize: '0.75em',
                            color: '#8e8e8e',
                            marginBottom: 7,
                        }}
                        className="mt-3 text-uppercase"
                    >
                      NAMA LENGKAP
                    </div>
                    <div>
                        {profileData.member && profileData.member.full_name
                            ? profileData.member.full_name
                            : '-'}
                    </div>

                    <hr></hr>

                    <div
                        style={{
                            fontFamily: 'Signika',
                            fontWeight: '600',
                            fontSize: '0.75em',
                            color: '#8e8e8e',
                            marginBottom: 7,
                        }}
                        className="mt-3 text-uppercase"
                    >
                        SUREL
                    </div>
                    <div>
                        {profileData.member && profileData.member.email
                            ? profileData.member.email
                            : '-'}{' '}
                    </div>

                    <hr></hr>

                    <div
                        style={{
                            fontFamily: 'Signika',
                            fontWeight: '600',
                            fontSize: '0.75em',
                            color: '#8e8e8e',
                            marginBottom: 7,
                        }}
                        className="mt-3 text-uppercase"
                    >
                        Jenis Kelamin
                    </div>
                    <div>
                        {profileData.member && profileData.member.gender
                            ? TextTranslator.getJenisKelamin(profileData.member.gender)
                            : '-'}{' '}
                    </div>

                    <hr></hr>

                    <div
                        style={{
                            fontFamily: 'Signika',
                            fontWeight: '600',
                            fontSize: '0.75em',
                            color: '#8e8e8e',
                        }}
                        className="mt-3 text-uppercase"
                    >
                        Tempat Lahir
                    </div>
                    <div>
                        {profileData.member && profileData.member.birth_place
                            ? profileData.member.birth_place
                            : '-'}
                    </div>

                    <hr></hr>


                    <div
                        style={{
                            fontFamily: 'Signika',
                            fontWeight: '600',
                            fontSize: '0.75em',
                            color: '#8e8e8e',
                        }}
                        className="mt-3 text-uppercase"
                    >
                        Tanggal Lahir
                    </div>
                    <div>
                        {profileData.member && profileData.member.birth_date
                            ? moment(profileData.member.birth_date).format('DD MMM YYYY')
                            : '-'}
                    </div>

                    <hr></hr>
                    <div
                        style={{
                            fontFamily: 'Signika',
                            fontWeight: '600',
                            fontSize: '0.75em',
                            color: '#8e8e8e',
                        }}
                        className="mt-3 text-uppercase"
                    >
                       Provinsi
                    </div>
                    
                    <div>
                        {profileData.member && profileData.member.address_region
                            ? profileData.member.address_region
                            : '-'}
                    </div>

                    <hr></hr>

                    <div
                        style={{
                            fontFamily: 'Signika',
                            fontWeight: '600',
                            fontSize: '0.75em',
                            color: '#8e8e8e',
                        }}
                        className="mt-3 text-uppercase"
                    >
                      Kabupaten/Kota
                    </div>
                    <div>
                        {profileData.member && profileData.member.address_locality
                            ? profileData.member.address_locality
                            : '-'}
                    </div>

                    <hr></hr>

                    <div
                        style={{
                            fontFamily: 'Signika',
                            fontWeight: '600',
                            fontSize: '0.75em',
                            color: '#8e8e8e',
                        }}
                        className="mt-3 text-uppercase"
                    >
                        Alamat Sekarang (Jika berbeda dengan KTP)
                    </div>
                    <div>
                        {profileData.member && profileData.member.ss_address
                            ? profileData.member.ss_address
                            : '-'}
                    </div>

                    <hr></hr>

                    <div
                        style={{
                            fontFamily: 'Signika',
                            fontWeight: '600',
                            fontSize: '0.75em',
                            color: '#8e8e8e',
                        }}
                        className="mt-3 text-uppercase"
                    >
                        Alamat sesuai KTP
                    </div>
                    <div>
                        {profileData.member && profileData.member.address
                            ? profileData.member.address
                            : '-'}
                    </div>

                    <hr></hr>

                    <div
                        style={{
                            fontFamily: 'Signika',
                            fontWeight: '600',
                            fontSize: '0.75em',
                            color: '#8e8e8e',
                        }}
                        className="mt-3 text-uppercase"
                    >
                        Kode Pos
                    </div>
                    <div>
                        {profileData.member && profileData.member.address_postal
                            ? profileData.member.address_postal
                            : '-'}
                    </div>

                    <hr></hr>

                    <div
                        style={{
                            fontFamily: 'Signika',
                            fontWeight: '600',
                            fontSize: '0.75em',
                            color: '#8e8e8e',
                        }}
                        className="mt-3 text-uppercase"
                    >
                       Nomor Telepon Seluler
                    </div>
                    <div>
                        {profileData.member && profileData.member.phone_number
                            ? profileData.member.phone_number
                            : '-'}
                    </div>

                    <hr></hr>

                    <div
                        style={{
                            fontFamily: 'Signika',
                            fontWeight: '600',
                            fontSize: '0.75em',
                            color: '#8e8e8e',
                        }}
                        className="mt-3 text-uppercase"
                    >
                        Nomor Telepon Kedua (opsional)
                    </div>
                    <div>
                        {profileData.member && profileData.member.landline_number
                            ? profileData.member.landline_number
                            : '-'}
                    </div>

                    <div style={{height : "2em"}}>

                    </div>
                </Col>
            </Row>

            <EditProfilePictureModal
                close={(refresh) => {
                    if (refresh) {
                        fetchSelfData()
                    }
                    setIsUpdatingProfilePicture(false)
                }}
                show={isUpdatingProfilePicture}
            />
        </div>
    )
}

export default MembershipPage
