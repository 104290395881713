import Container from 'react-bootstrap/Container'
import React, { useState, useEffect } from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import moment from 'moment'
import { useHistory } from 'react-router-dom'

import Content from '../models/Content'
import defaultThumbnail from "../assets/default.jpg"
import ReactPaginate from "react-paginate";
import {isMobile} from "react-device-detect";

function ContentPage(props) {

    let history = useHistory()
    const [contents, setContents] = useState([])

    let content = new Content()

    const [currentPage, setCurrentPage] = useState(0);
    const [postsPerPage]= useState(10)

    const getContents = async () => {
        try {
            let result = await content.getAll()
            console.log('contents : ', result)
            setContents(result)
        } catch (e) {
            console.log(e)
        }
    }

    const indexOfLastPost = currentPage * postsPerPage

    const currentPageData = contents.slice(indexOfLastPost, indexOfLastPost + postsPerPage)
    const pageCount = Math.ceil(contents.length/postsPerPage)

    const handlePageClick = ({selected: selectedPage}) => {
        setCurrentPage(selectedPage)
    }

    const handleContentDetail = (id) => {
        history.push(`/member/konten/${id}`)
    }

    useEffect(() => {
        getContents()
    }, [])

    return (
        <Container className="text-left">
            {currentPageData?.map((content) => {
                return (
                    // <DataTable
                    //     customStyles={Styles.dataTable}
                    //     columns={[
                    //         {
                    //             name: 'ID Registrasi',
                    //             selector: (row) =>,
                    //             sortable
                    //         }
                    //     ]}>
                    //
                    // </DataTable>
                    <Row
                        style={{
                            cursor : "pointer",
                        }}
                        className="my-3 px-3 py-2 shadow-sm"
                        onClick={() => handleContentDetail(content.id)}
                    >
                        <Col className="col-3">
                            <img
                                src={content?.thumbnail_url ? content?.thumbnail_url: defaultThumbnail}
                                alt={content?.title}
                                style={{
                                    maxWidth: '100%',
                                    maxHeight: '100%',
                                }}
                            ></img>
                        </Col>
                        <Col>
                            <div
                                style={{
                                    fontFamily: 'Signika',
                                    fontWeight: 'bold',
                                    fontSize: '18px',
                                    color: 'black',
                                }}
                            >
                                {content.title}
                            </div>
                            <div
                                style={{
                                    fontFamily: 'Signika',
                                    fontWeight: '600',
                                    fontSize: '0.75em',
                                    color: '#8e8e8e',
                                }}
                            >
                                {moment(content.created_at).format('dddd, DD MMM YYYY')}
                            </div>
                        </Col>
                    </Row>
                )
            })}
            <Row style={{marginBottom: 15}}>
                <ReactPaginate
                    pageCount={pageCount}
                    nextLabel={<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                    className="bi bi-chevron-compact-right" viewBox="0 0 16 16">
                        <path fill-rule="evenodd"
                              d="M6.776 1.553a.5.5 0 0 1 .671.223l3 6a.5.5 0 0 1 0 .448l-3 6a.5.5 0 1 1-.894-.448L9.44 8 6.553 2.224a.5.5 0 0 1 .223-.671z"/>
                    </svg>}
                    previousLabel={<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                        fill="currentColor" className="bi bi-chevron-compact-left"
                                        viewBox="0 0 16 16">
                        <path fill-rule="evenodd"
                              d="M9.224 1.553a.5.5 0 0 1 .223.67L6.56 8l2.888 5.776a.5.5 0 1 1-.894.448l-3-6a.5.5 0 0 1 0-.448l3-6a.5.5 0 0 1 .67-.223z"/>
                    </svg>}
                    onPageChange={handlePageClick}
                    containerClassName={"pagination"}
                    previousLinkClassName={"pagination__link"}
                    nextLinkClassName={"pagination__link"}
                    disabledClassName={"pagination__link--disabled"}
                    activeClassName={"pagination__link--active"}
                />
            </Row>

        </Container>
    )
}

export default ContentPage
