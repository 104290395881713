import React, { useEffect, useState } from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { isMobile } from 'react-device-detect'
import { useHistory } from 'react-router-dom'

export default function RecordingPage(props) {
    let history = useHistory()
    const [url, setUrl] = useState(null)

    useEffect(() => {
        const recordingUrl = props.location?.state?.url

        if (!recordingUrl) {
            history.push('/')
        } else {
            setUrl(recordingUrl)
        }
    }, [])

    const embeddedVideo = (url) => {
        let embedlink = ''
        if (url !== undefined) {
            var id = url.split('?v=')[1] //sGbxmsDFVnE

            embedlink = 'https://www.youtube.com/embed/' + id
        }

        return embedlink
    }

    return (
        <Container>
            <Row
                style={{
                    marginTop: isMobile ? 0 : '1em',
                    fontFamily: 'OpenSans-Bold',
                }}
            >
                <Col md={12}>
                    <div className="embed-responsive embed-responsive-16by9 custom-video-ratio">
                        {url && (url.includes('youtube') || url.includes('youtu.be')) ? (
                            <>
                                <iframe
                                    allow="fullscreen"
                                    className="embed-responsive-item"
                                    type="text/html"
                                    title=" "
                                    src={embeddedVideo(url)}
                                    frameBorder="0"
                                ></iframe>
                            </>
                        ) : (
                            <video
                                onContextMenu={(e) => {
                                    e.preventDefault()
                                }}
                                controlsList="nodownload"
                                disablePictureInPicture
                                controls
                                width={'100%'}
                                height={'100%'}
                            >
                                <source src={url} type="video/mp4" />
                            </video>
                        )}
                    </div>
                </Col>
            </Row>
        </Container>
    )
}
