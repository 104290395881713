import ApiRequest from '../util/ApiRequest'

export default class Class {
    getAllClasses = async (id) => {
        return await ApiRequest.set('v1/available_classes', 'GET')
    }

    getAllMyClasses = async () => {
        return await ApiRequest.set('v1/member_class/myClass', 'GET')
    }

    getClassDetail = async (id) => {
        return await ApiRequest.set('v1/available_classes/' + id, 'GET')
    }

    registerClass = async (id, voucherCode) => {
        let body = { class_id: id}
        if(voucherCode) body.voucherCode = voucherCode
        return await ApiRequest.set('v1/class/register', 'POST', body)
    }
}
