import {Button, FormControl, InputGroup, Modal} from "react-bootstrap";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import {AiOutlineClose} from "react-icons/all";
import Palette from "../../Palette";
import Col from "react-bootstrap/Col";
import React, {useState} from "react";
import {MdArchive, MdKeyboard} from "react-icons/md";
import Upload from "../../models/Upload";

export default function EditProfileAddMembershipModal(props) {

    const {show, close, onSubmit} = props

    const [isEditLinkFocused, setIsEditLinkFocused] = useState(false)
    const [isEditOrganizationFocused, setIsEditOrganizationFocused] = useState(false)

    const [organizationName, setOrganizationName] = useState(null)
    const [url, setUrl] = useState(null)

    const resetAndClose = () =>{
        reset()
        close()
    }

    const reset =()=>{
        setOrganizationName(null)
        setUrl(null)
    }

    return <Modal show={show} onHide={() => {
        resetAndClose()
    }}
    >
        <Container>
            <Row style={{
                marginTop: 15,
                marginRight: 15,
                display: 'flex',
                justifyContent: 'flex-end',
                marginBottom: -30
            }}>
                <AiOutlineClose size={25} style={{cursor: 'pointer'}} onClick={() => {
                    resetAndClose()
                }}/>
            </Row>

            <Container>
                <Row style={{display: 'flex', justifyContent: 'center', marginTop: 25, marginBottom: 15}}>

                    <Col md={12}>
                        <div
                            style={{
                                fontFamily: 'Signika',
                                fontWeight: '600',
                                fontSize: '0.75em',
                                color: '#8e8e8e',
                                marginBottom: 7,
                            }}
                            className="mt-3 text-uppercase"
                        >
                            Tambah informasi keanggotaan
                        </div>
                    </Col>

                    <Col
                        style={{
                            paddingBottom: 10
                        }}
                        md={12}>
                        <InputGroup
                            style={
                                isEditOrganizationFocused
                                    ? {
                                        boxShadow: '0 0 10px #9ecaed',
                                        border: '1px solid  #9ecaed',
                                        borderRadius: 5,
                                    }
                                    : {}
                            }
                        >
                            <InputGroup.Prepend>
                                <InputGroup.Text
                                    style={{
                                        borderWidth: 0,
                                        display: 'flex',
                                        alignItems: 'center',
                                        backgroundColor: '#e9f3f4',
                                    }}
                                >
                                    <MdKeyboard size={20}/>
                                </InputGroup.Text>

                                <InputGroup.Text
                                    style={{
                                        borderWidth: 0,
                                        display: 'flex',
                                        alignItems: 'center',
                                        paddingLeft: 0,
                                        paddingRight: 0,
                                    }}
                                >
                                    <div
                                        style={{
                                            height: '75%',
                                            width: 2,
                                            backgroundColor: '#9ca4a6',
                                        }}
                                    />
                                </InputGroup.Text>
                            </InputGroup.Prepend>

                            <FormControl
                                placeholder={
                                    'Nama Organisasi'
                                }
                                style={{
                                    borderWidth: 0,
                                    backgroundColor: '#e9f3f4',
                                    outline: 'none',
                                    boxShadow: 'none',
                                    fontFamily: 'Signika',
                                    fontWeight: '600',
                                }}
                                onChange={(event) => setOrganizationName(event.target.value)}
                                onFocus={() => setIsEditOrganizationFocused(true)}
                                onBlur={() => setIsEditOrganizationFocused(false)}
                                value={organizationName}
                            />
                        </InputGroup>
                    </Col>

                    <Col
                        style={{
                            paddingBottom: 10
                        }}
                        md={12}>
                        <InputGroup
                            style={
                                isEditLinkFocused
                                    ? {
                                        boxShadow: '0 0 10px #9ecaed',
                                        border: '1px solid  #9ecaed',
                                        borderRadius: 5,
                                    }
                                    : {}
                            }
                        >
                            <InputGroup.Prepend>
                                <InputGroup.Text
                                    style={{
                                        borderWidth: 0,
                                        display: 'flex',
                                        alignItems: 'center',
                                        backgroundColor: '#e9f3f4',
                                    }}
                                >
                                    <MdKeyboard size={20}/>
                                </InputGroup.Text>

                                <InputGroup.Text
                                    style={{
                                        borderWidth: 0,
                                        display: 'flex',
                                        alignItems: 'center',
                                        paddingLeft: 0,
                                        paddingRight: 0,
                                    }}
                                >
                                    <div
                                        style={{
                                            height: '75%',
                                            width: 2,
                                            backgroundColor: '#9ca4a6',
                                        }}
                                    />
                                </InputGroup.Text>
                            </InputGroup.Prepend>

                            <FormControl
                                placeholder={
                                    'URL'
                                }
                                style={{
                                    borderWidth: 0,
                                    backgroundColor: '#e9f3f4',
                                    outline: 'none',
                                    boxShadow: 'none',
                                    fontFamily: 'Signika',
                                    fontWeight: '600',
                                }}
                                onChange={(event) => setUrl(event.target.value)}
                                onFocus={() => setIsEditLinkFocused(true)}
                                onBlur={() => setIsEditLinkFocused(false)}
                                value={url}
                            />
                        </InputGroup>
                    </Col>

                </Row>

                <Row>
                    <Col md={12} style={{
                        marginBottom : 15,
                        display : "flex",
                        justifyContent : "flex-end"
                    }}>
                        <Button
                            onClick={()=>{
                                onSubmit({
                                    organization : organizationName,
                                    url : url
                                })
                                resetAndClose()
                            }}
                            disabled={!organizationName || !url}
                            style={{
                                backgroundColor: Palette.PRIMARY,
                                borderColor: Palette.PRIMARY,
                                fontFamily: 'Signika',
                            }}
                            size={"sm"}>+ Add</Button>
                    </Col>
                </Row>
            </Container>

        </Container>
    </Modal>

}
