import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import 'moment/locale/id'

import React, {useState} from 'react';

import {BrowserRouter, Route, Switch} from "react-router-dom"
import MainContainer from "./components/MainContainer";


function App() {
    return (
        <BrowserRouter basename="/">
            {/*<BrowserRouter>*/}
            <MainContainer/>

        </BrowserRouter>
    );
}

export default App;
