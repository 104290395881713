import React, {useMemo, useEffect, useState} from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'
import {FaCheckCircle} from 'react-icons/fa'
import {Link, useHistory} from 'react-router-dom'
import {FaExclamationCircle, FaInfoCircle, FaTimesCircle} from "react-icons/all";
import Palette from "../Palette";
import {Button} from "react-bootstrap";

export default function PaymentResult(props) {

    const history = useHistory()

    const [statusImg, setStatusImg] = useState('');
    const [paymentStatus, setPaymentStatus] = useState('');

    useEffect(() => {
        const paymentStatus = new URLSearchParams(props.location.search).get('transaction_status');

        if (paymentStatus === "settlement" || paymentStatus === "capture") {
            setStatusImg(
                <>
                    <FaCheckCircle style={{
                        fontSize: '5em',
                        color: '#437e25'
                    }}/>
                    <h1 style={{
                        color: '#437e25',
                        fontWeight: 'bold',
                        fontSize: '1.5em'
                    }}>Pembayaran Berhasil!</h1>
                </>
            );
            setPaymentStatus('Pembayaran Berhasil!')
        } else if (paymentStatus === "deny") {
            setStatusImg(
                <>
                    <FaTimesCircle style={{
                        fontSize: '5em',
                        color: '#ff0000'
                    }}/>
                    <h1 style={{
                        color: '#ff0000',
                        fontWeight: 'bold',
                        fontSize: '1.5em'
                    }}>Pembayaran Gagal!</h1>
                </>
            );
            setPaymentStatus('Pembayaran Gagal')
        } else if (paymentStatus === "pending") {
            setStatusImg(
                <>
                    <FaInfoCircle style={{
                        fontSize: '5em',
                        color: Palette.PRIMARY
                    }}/>
                    <h1 style={{
                        color: '#ff0000',
                        fontWeight: 'bold',
                        fontSize: '1.5em'
                    }}>Menunggu Pembayaran</h1>
                </>
            );
        } else {
            setStatusImg(
                <>
                    <FaTimesCircle style={{
                        fontSize: '5em',
                        color: '#ff0000'
                    }}></FaTimesCircle>
                    <h1 style={{
                        color: '#ff0000',
                        fontWeight: 'bold',
                        fontSize: '1.5em'
                    }}>Terjadi Kesalahan</h1>
                </>

            );

            setPaymentStatus('An Error Occured')
            // history.push("/")
        }
    }, [])


    return (
        <>
            <Row
                style={{minHeight: "70vh"}}
                className={'justify-content-md-center'}>
                <Col
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "100%",
                        padding: 0
                    }}
                    md={6}>
                    <Card style={{width: "100%"}}>
                        <Card.Body>
                            <div style={{textAlign: 'center'}}>

                                {statusImg}

                                <Link to={"/member/membership"}>
                                    <Button style={{
                                        marginTop : 10
                                    }}>
                                        Kembali
                                    </Button>
                                </Link>

                                {/*<FaCheckCircle style={{*/}
                                {/*    fontSize: '5em',*/}
                                {/*    color: '#437e25'*/}
                                {/*}}></FaCheckCircle>*/}
                                {/*<h1 style={{*/}
                                {/*    color: '#437e25',*/}
                                {/*    fontWeight: 'bold',*/}
                                {/*    fontSize: '3em'*/}
                                {/*}}>Success</h1>*/}

                                {/*<p style={{marginBottom: '20px'}}>{paymentStatus}</p>*/}

                                {/*<div style={{display: 'flex', marginBottom: '10px'}}>*/}
                                {/*    <p style={{flex: 1, textAlign: 'left', alignItems: 'flex-start'}}>Transaction*/}
                                {/*        Number</p>*/}
                                {/*    <p style={{*/}
                                {/*        flex: 1,*/}
                                {/*        textAlign: 'right',*/}
                                {/*        alignItems: 'flex-end',*/}
                                {/*        fontWeight: 700*/}
                                {/*    }}>#10102020102192</p>*/}
                                {/*</div>*/}

                                {/*<div style={{display: 'flex', marginBottom: '10px'}}>*/}
                                {/*    <p style={{flex: 1, textAlign: 'left', alignItems: 'flex-start'}}>Amount</p>*/}
                                {/*    <p style={{*/}
                                {/*        flex: 1,*/}
                                {/*        textAlign: 'right',*/}
                                {/*        alignItems: 'flex-end',*/}
                                {/*        fontWeight: 700*/}
                                {/*    }}>IDR.200.0000</p>*/}
                                {/*</div>*/}
                                {/*<div style={{display: 'flex', marginBottom: '10px'}}>*/}
                                {/*    <p style={{flex: 1, textAlign: 'left', alignItems: 'flex-start'}}>Date</p>*/}
                                {/*    <p style={{flex: 1, textAlign: 'right', alignItems: 'flex-end', fontWeight: 700}}>11*/}
                                {/*        Mar 2022</p>*/}
                                {/*</div>*/}
                                {/*<div style={{display: 'flex', marginBottom: '10px'}}>*/}
                                {/*    <p style={{flex: 1, textAlign: 'left', alignItems: 'flex-start'}}>Payment Method</p>*/}
                                {/*    <p style={{*/}
                                {/*        flex: 1,*/}
                                {/*        textAlign: 'right',*/}
                                {/*        alignItems: 'flex-end',*/}
                                {/*        fontWeight: 700*/}
                                {/*    }}>Credit Card</p>*/}
                                {/*</div>*/}

                            </div>

                        </Card.Body>

                    </Card>
                </Col>
            </Row>
        </>
    )
}
