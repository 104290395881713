import React, { useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import Container from 'react-bootstrap/Container'
import { InputGroup, Button, Row, Col } from 'react-bootstrap'
import { MdHelp } from 'react-icons/md'
import Select, { components } from 'react-select'
import { FaAngleDown } from 'react-icons/fa'
import Swal from "sweetalert2";
import { AiFillCloseCircle, FaMinus, FaPlus } from 'react-icons/all'
import Alert from '../Alert'

import Upload from '../../models/Upload'
import Member from '../../models/Member'

const translatorFullMemberReqOptions = [
    {
        label: '1. Telah menerjemahkan buku dan diterbitkan oleh penerbit terdaftar IKAPI',
        value: '1',
    },
    {
        label: '2. Melampirkan contoh terjemahan (beserta teks sumber) dengan volume 150.000 karakter termasuk spasi',
        value: '2',
    },
    { label: '3. Merupakan Penerjemah Tersumpah (SK Mentri)', value: '3' },
    { label: '4. Merupakan Penerjmah Tersumpah (SK Gubernur DKI Jakarta)', value: '4' },
    {
        label: '5. Telah bekerja (minimal) satu tahun di sebuah perusahaan sebagai penerjemah atau jabatan terkait penerjemah',
        value: '5',
    },
    { label: '6. Surat rujukan/referensi dari 3(tiga) klien yang berbeda', value: '6' },
]

export default function UpgradeMembershipModal(props) {
    let { data, show, close } = props

    console.log('isi datanya : ', data)

    const [errorMsg, setErrorMsg] = useState(null)
    const [translatorFullMemberReqLabel, setTranslatorFullMemberReqLabel] = useState('')
    const [translatorFullMemberReqType, setTranslatorFullMemberReqType] = useState(null)
    const [translatorFullMemberReqLabelError, setTranslatorFullMemberReqLabelError] = useState('')
    const [profileAgreement, setProfileAgreement] = useState('')
    const [translatorFullMemberReq, setTranslatorFullMemberReq] = useState([])
    const [interpreterFullMemberReq, setInterpreterFullMemberReq] = useState([])

    const [translatorFullMemberReqError, setTranslatorFullMemberReqError] = useState('')
    const [interpreterFullMemberReqError, setInterpreterFullMemberReqError] = useState('')
    const [profileAgreementError, setProfileAgreementError] = useState('')

    const handleUploadInterpreterReq = async (event) => {
        console.log(event)
        console.log('upgrade member data', data)

        try {
            const model = new Upload()

            const formData = new FormData()

            console.log('ETF', event.target.files[0])

            formData.append('upload', event.target.files[0])
            formData.append('member_id', '-1')

            const result = await model.uploadImage(formData)
            let obj = {
                id: interpreterFullMemberReq.length + 1,
                name: event.target.files[0].name,
                location: result?.location
            }
            // setInterpreterFullMemberReq(result.location)
            console.log("TESTING INTER", obj, [...translatorFullMemberReq, obj])
            setInterpreterFullMemberReq([...interpreterFullMemberReq, obj])
        } catch (e) {
            console.log('ETF ERROR', e)
        }
    }

    const handleUploadTranslatorReq = async (event) => {
        console.log(event)

        try {
            const model = new Upload()

            const formData = new FormData()

            console.log('ETF', event.target.files[0])

            formData.append('upload', event.target.files[0])
            formData.append('member_id', '-1')

            const result = await model.uploadImage(formData)
            let obj = {
                id: translatorFullMemberReq.length + 1,
                name: event.target.files[0].name,
                location: result?.location
            }
            console.log("TESTING TRANS", obj, [...translatorFullMemberReq, obj])
            setTranslatorFullMemberReq([...translatorFullMemberReq, obj])
        } catch (e) {
            console.log('ETF ERROR', e)
        }
    }

    const DropdownIndicator = (props) => {
        return (
            components.DropdownIndicator && (
                <components.DropdownIndicator {...props}>
                    <FaAngleDown color={'grey'} />
                </components.DropdownIndicator>
            )
        )
    }

    const upgrade = async () => {
        let payload = {};
        let fileRegistInterpreter = interpreterFullMemberReq?.map((v) => {
            return v.location
        });
        let fileRegistTranslator = translatorFullMemberReq?.map((v) => {
            return v.location
        });;
        let fileRegReqType = translatorFullMemberReqType
        let profession = data?.member?.profession;

        // return console.log(fileRegistTranslator, fileRegistTranslator)

        switch (profession) {
            case "Translator":
                if (translatorFullMemberReqLabel?.length === 0) {
                    Swal.fire('', 'Harap memilih syarat pendaftaran', 'error')
                    return
                }
                if (fileRegistTranslator?.length === 0) {
                    Swal.fire('', 'Harap unggah pemenuhan Syarat Penerjemah', 'error')
                    return
                }

                payload.file_registration_requirements_type = fileRegReqType
                payload.file_registration_requirements = fileRegistTranslator
                break;
            case "Interpreter":
                if (fileRegistInterpreter?.length === 0) {
                    Swal.fire('', 'Harap unggah pemenuhan syarat Juru Bahasa', 'error')
                    return
                }
                payload.file_reference_letter_interpreter = fileRegistInterpreter
                break
            default:

                if (fileRegistTranslator?.length === 0) {
                    Swal.fire('', 'Harap unggah pemenuhan Syarat Penerjemah', 'error')
                    return
                }

                if (translatorFullMemberReqLabel?.length === 0) {
                    Swal.fire('', 'Harap memilih syarat pendaftaran', 'error')
                    return
                }

                if (fileRegistInterpreter?.length === 0) {
                    Swal.fire('', 'Harap unggah pemenuhan syarat Juru Bahasa', 'error')
                    return
                }

                payload.file_registration_requirements_type = fileRegReqType
                payload.file_registration_requirements = [...fileRegistTranslator]
                payload.file_reference_letter_interpreter = [...fileRegistInterpreter]
        }

        if (profileAgreement?.length === 0) {
            Swal.fire('', 'Harap pilih Pernyataan kebersediaan menampilkan profile', 'error')
            return
        }

        try {
            let model = new Member()

            payload.is_public = profileAgreement;
            let result = await model.upgradeMembership(payload)

            console.log('payload result', result)
            await Swal.fire({
                title: 'Informasi Peningkatan Status Saudara telah tersimpan',
                text: 'Mohon menunggu, pengajuan peningkatan status Saudara sedang kami proses.',
                icon: 'info',
                confirmButtonText: 'OKE',
            })
            window.location.reload()
            close()
        } catch (e) {
            console.log(e)
            await Swal.fire({
                title: 'Terjadi Kesalahan',
                text: e.error_message,
                icon: 'error',
                confirmButtonText: 'OKE',
            })
        }

    }

    return (
        <Modal show={show} onHide={close} size={'lg'}>
            <Modal.Header closeButton>Syarat Peningkatan Status Keanggotaan</Modal.Header>
            <Modal.Body>
                <Container>
                    {errorMsg ? <Alert type="error" className='mx-0' errorMsg={errorMsg}></Alert> : null}
                    {
                        data?.member?.profession !== 'Interpreter' && (
                            <>
                                {translatorFullMemberReqLabelError && <Alert type="error" className='mx-0' errorMsg={'Harap memilih syarat pendaftaran'}></Alert>}
                                <InputGroup>
                                    <InputGroup.Prepend>
                                        <InputGroup.Text
                                            style={{
                                                borderWidth: 0,
                                                display: 'flex',
                                                alignItems: 'center',
                                                backgroundColor: '#e9f3f4',
                                            }}
                                        >
                                            <MdHelp size={20} />
                                        </InputGroup.Text>
                                    </InputGroup.Prepend>

                                    <div style={{ flex: 1 }}>
                                        <Select
                                            onChange={(option) => {
                                                console.log('Province has been changed!')
                                                setTranslatorFullMemberReqLabel(option.label)
                                                setTranslatorFullMemberReqType(option.value)
                                                setTranslatorFullMemberReqError(option.value.length === 0)
                                            }}
                                            placeholder={'Pilih syarat pendaftaran'}
                                            options={translatorFullMemberReqOptions}
                                            styles={{
                                                option: (provided, state) => ({
                                                    ...provided,
                                                    cursor: 'pointer',
                                                }),
                                                control: (provided) => ({
                                                    ...provided,
                                                    borderWidth: 0,
                                                    backgroundColor: '#e9f3f4',
                                                    fontFamily: 'Signika',
                                                    fontWeight: '600',
                                                    color: '#8e8e8e',
                                                }),
                                            }}
                                            components={{
                                                DropdownIndicator,
                                                IndicatorSeparator: () => null,
                                            }}
                                            value={translatorFullMemberReqOptions.filter(
                                                (option) => option.label === translatorFullMemberReqLabel,
                                            )}
                                        />
                                    </div>
                                </InputGroup>

                                <div className="mt-3 register-form-label">
                                    Pemenuhan Syarat - {translatorFullMemberReqLabel}
                                </div>

                                <label className="btn btn-sm btn-secondary" for="translatorReq">
                                    Unggah
                                </label>

                                <input
                                    style={{ display: 'none' }}
                                    id="translatorReq"
                                    type="file"
                                    onChange={handleUploadTranslatorReq}
                                />
                                {translatorFullMemberReqError && <Alert type="error" className='mx-0' errorMsg={'Bagian ini harus diisi'}></Alert>}
                                <Row>
                                    <Col md={12}>
                                        {translatorFullMemberReq && translatorFullMemberReq?.map((values, key) => {
                                            return (
                                                <>
                                                    <a href={values.location} alt='' target="_blank">
                                                        <Button variant='link'>
                                                            {values.name}
                                                        </Button>
                                                    </a>
                                                    <a className='text-secondary' onClick={(e) => {
                                                        let filtered = translatorFullMemberReq?.filter((v, key) => {
                                                            return v?.id !== values?.id
                                                        })
                                                        setTranslatorFullMemberReq(filtered)
                                                    }} href='javascript:;'>
                                                        <AiFillCloseCircle />
                                                    </a>
                                                </>
                                            )
                                        })}
                                    </Col>
                                </Row>


                            </>
                        )
                    }

                    {
                        data?.member?.profession !== 'Translator' && (
                            <>
                                <div className="mt-3 register-form-label">
                                    SYARAT PENDAFTARAN SEBAGAI ANGGOTA PENUH (JURU BAHASA) - 
                                    Surat rujukan/referensi dari 3 (tiga) klien yang berbeda (dengan mencantumkan nama, alamat, 
                                    dan nomor yang dapat dihubungi) yang menunjukkan bahwa Saudara telah melakukan pekerjaan yang 
                                    memuaskan sebagai juru bahasa dengan total jam kerja minimal 50 (lima puluh) jam.
                                </div>

                                <label className="btn btn-sm btn-secondary" for="interpreterReq">
                                    Upload
                                </label>

                                <input
                                    style={{ display: 'none' }}
                                    id="interpreterReq"
                                    type="file"
                                    onChange={handleUploadInterpreterReq}
                                />
                                {interpreterFullMemberReqError && <Alert type="error" className='mx-0' errorMsg={'Bagian ini harus diisi'}></Alert>}
                                <Row>
                                    <Col md={12}>
                                        {interpreterFullMemberReq &&
                                            interpreterFullMemberReq?.map((value, key) => {
                                                return (
                                                    <>
                                                        <a href={value?.location} alt='' target="_blank">
                                                            <Button variant='link'>
                                                                {value?.name}
                                                            </Button>
                                                        </a>

                                                        <a className='text-secondary' onClick={(e) => {
                                                            let filtered = interpreterFullMemberReq?.filter((v, key) => {
                                                                return v?.id !== value?.id
                                                            })
                                                            setInterpreterFullMemberReq(filtered)
                                                        }} href='javascript:;'>
                                                            <AiFillCloseCircle />
                                                        </a>
                                                    </>
                                                )
                                            })


                                        }
                                    </Col>
                                </Row>

                            </>
                        )
                    }


                    <div className="mt-3 register-form-label">
                        PERNYATAAN KEBERSEDIAAN UNTUK MENAMPILKAN PROFIL DI DIREKTORI PENERJEMAH DAN
                        JURU BAHASA INDONESIA JIKA DITERIMA SEBAGAI ANGGOTA PENUH
                    </div>

                    <div style={{ display: 'grid' }}>
                        <div>
                            <input
                                name="gender"
                                type="radio"
                                value={true}
                                onClick={() => {
                                    setProfileAgreement(true)
                                    setProfileAgreementError(false)
                                }}
                            />{' '}
                            Saya Bersedia
                        </div>
                        <div>
                            <input
                                name="gender"
                                type="radio"
                                value={false}
                                onClick={() => {
                                    setProfileAgreement(false)
                                    setProfileAgreementError(false)
                                }}
                            />{' '}
                            Saya Tidak Bersedia
                        </div>
                    </div>
                    {profileAgreementError && <Alert type="error" className='mx-0' errorMsg={'Bagian ini harus diisi'}></Alert>}

                    <Button onClick={upgrade} className='w-100 mt-4'>
                        Ajukan
                    </Button>
                </Container>
            </Modal.Body>
        </Modal>
    )
}
