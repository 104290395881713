import Container from 'react-bootstrap/Container'
import React, { useEffect, useState } from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { Tabs, Tab, OverlayTrigger, Tooltip } from 'react-bootstrap'
import Button from 'react-bootstrap/Button'
import { isMobile } from 'react-device-detect'
import parse from 'html-react-parser'
import { AiOutlineLink, FaDownload, FaEdit, IoMdWarning, MdError } from 'react-icons/all'
import { IconButton } from '@material-ui/core'

import Palette from '../Palette'
import profilePicture from '../assets/default_user.png'
import MembershipPage from './MembershipPage'
import Member from '../models/Member'
import EditProfilePictureModal from '../components/Modals/EditProfilePictureModal'
import TextTranslator from "../util/TextTranslator";
import MemberCertificatePage from './MemberCertificatePage'
import moment from 'moment'
import ReminderDebtModal from '../components/Modals/ReminderDebtModal'
import Swal from 'sweetalert2'

function ProfilePage(props) {

    const [profileStatus, setProfileStatus] = useState("NONE")
    const [isMembershipComplete, setMembershipComplete] = useState(true)

    console.log("AZC LG", profileStatus)

    const [selectedInvoiceDetail, setSelectedInvoiceDetail] = useState(null)
    const [validateMsg, setValidateMsg] = useState('')
    const [isOpenValidate, setIsOpenValidate] = useState(false)
    const fetchSelfData = async () => {
        let memberModel = new Member()

        try {
            let result = await memberModel.getMemberData()

            // if (result?.membership?.length > 0) {
            //     const validResult = result?.membership[0].valid_until
            //
            //     console.log('valid', validResult)
            //     if (moment(validResult).add(30, 'days') <= moment()) {
            //         const dueDateMembership = moment(validResult?.valid_until).add(30, 'days').format('DD/MM/YYYY');
            //         setValidateMsg(`Iuran keanggotaan Saudara akan jatuh tempo pada tanggal ${dueDateMembership}. Kami mendorong Saudara untuk melakukan pembayaran secara tepat waktu agar Saudara dapat tetap mengakses akun Sihapei Saudara dan menikmati berbagai manfaatnya.`)
            //         if (!localStorage.getItem('popUpReminder')) {
            //             setIsOpenValidate(true)
            //         }
            //     } else if (moment() > moment(validResult?.valid_until).add(30, 'days')) {
            //         setValidateMsg(`Akun anda telah di nonaktifkan. harap selesaikan tunggakan anda dengan menghubungi sekretariat hpi`)
            //         if (!localStorage.getItem('popUpReminder')) {
            //             setIsOpenValidate(true)
            //         }
            //     } else {
            //         setIsOpenValidate(false)
            //     }
            // }



            //isMembershipComplete
            //"email", "password", "full_name", "type", "gender"

            if (
                !result.member.full_name ||
                !result.member.email ||
                !result.member.gender ||
                !result.member.birth_date ||
                !result.member.birth_place ||
                !result.member.address_region ||
                !result.member.address_locality ||
                // !result.member.address ||
                !result.member.ss_address ||
                !result.member.email ||
                !result.member.phone_number
            ) {
                setMembershipComplete(false)
            }

            if (
                !result.member.profession ||
                !result.member.availability ||
                result.languages.length === 0
            ) {
                console.log("AZC", result.member.profession)
                console.log("AZC", result.member.availability)
                console.log("AZC", result.member.occupation)
                console.log("AZC", result.languages.length)
                setProfileStatus("ERROR")
            } else if (
                !result.member.service_offered ||
                !(result.member.translator_since || result.member.interpreter_since) ||
                !result.member.preferred_currency ||
                !result.member.payment_method ||
                !result.member.software ||
                !result.member.about_me
            ) {
                setProfileStatus("WARNING")
            } else {
                setProfileStatus("NONE")
            }

            console.log("rslt", result)

            setProfileData(result)
        } catch (e) {
            console.log('ERROR' + e)
        }
    }

    useEffect(() => {
        fetchSelfData()
    }, [])

    const handleEdit = () => {
        if (props.match.path === '/member/akun') {
            props.history.push(props.match.path + '/edit')
        } else {
            props.history.push(props.match.path + '/akun/edit')
        }
    }

    const handleEditPassword = () => {
        props.history.push('/member/akun/editPassword')
    }

    const [profileData, setProfileData] = useState({})
    const [isUpdatingProfilePicture, setIsUpdatingProfilePicture] = useState(false)

    let profile = (
        <>
            <ReminderDebtModal
                message={validateMsg}
                isOpen={isOpenValidate}
                close={deleteState => {
                    if (deleteState) {
                        localStorage.setItem('popUpReminder', true)
                    }
                    setIsOpenValidate(false)
                }}
            />
            <Row
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginTop: 25,
                    marginBottom: 20,
                }}
            >
                <div
                    style={{
                        fontFamily: 'Signika',
                        fontWeight: '800',
                        fontSize: '1.75em',
                        color: Palette.PRIMARY,
                    }}
                    className={`text-center ${isMobile && 'w-100'}`}
                >
                    Profil
                </div>
                {!isMobile && (
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            right: '0',
                        }}
                        className={'position-absolute pr-4'}
                    >
                        {profileData.member?.hpi_id && <Button
                            style={{
                                fontFamily: 'Signika',
                                fontWeight: '600',
                                // paddingTop: 10,
                                // paddingBottom: 10,
                                backgroundColor: Palette.PRIMARY,
                                borderColor: Palette.PRIMARY,
                                marginBottom: 10,
                            }}
                            // className="text-uppercase"
                            onClick={handleEdit}
                        >
                            Ubah Profil
                        </Button>}

                        <Button
                            style={{
                                fontFamily: 'Signika',
                                fontWeight: '600',
                                // paddingTop: 10,
                                // paddingBottom: 10,
                                backgroundColor: Palette.PRIMARY,
                                borderColor: Palette.PRIMARY,
                                // margin: 10,
                                marginBottom: 10,
                            }}
                            onClick={handleEditPassword}
                        >
                            Ganti Sandi
                        </Button>

                        <Button
                            style={{
                                fontFamily: 'Signika',
                                fontWeight: '600',
                                // paddingTop: 10,
                                // paddingBottom: 10,
                                backgroundColor: Palette.PRIMARY,
                                borderColor: Palette.PRIMARY,
                                // margin: 10,
                            }}
                            onClick={()=>{
                                let copyText = `https://sihapei.hpi.or.id/anggota/${profileData?.member?.hpi_id}`;
                                navigator.clipboard.writeText(copyText);
                                Swal.fire({
                                    title: 'Link berhasil di salin',
                                    icon: 'success',
                                    confirmButtonText: 'OKE'
                                })
                            }}
                        >
                            Salin Link Profil
                        </Button>

                    </div>
                )}
            </Row>
            <Row
                style={{
                    fontFamily: 'Signika',
                    fontWeight: '600',
                    fontSize: '2em',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    cursor: 'pointer'
                }}
            >
                <img
                    src={
                        profileData?.member?.profile_picture_url
                            ? profileData.member.profile_picture_url
                            : profilePicture
                    }
                    style={{
                        width: 130,
                        objectFit: 'contain',
                    }}
                    alt="user"
                />

                <div
                    style={{
                        width: 130,
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'flex-end',
                    }}
                >
                    <IconButton
                        onClick={() => {
                            setIsUpdatingProfilePicture(true)
                        }}
                        style={{
                            marginTop: '-1em',
                            marginRight: '-0.5em',
                            background: 'white',
                            border: Palette.PRIMARY,
                            boxShadow: '1px 1px 0px rgba(100,100,100,.5)',
                        }}
                    >
                        <FaEdit size={14} />
                    </IconButton>
                </div>
                {isMobile && (
                    <div
                        style={{
                            display : "flex",
                            flexDirection: 'column',
                            right: '0',
                        }}
                    >
                        <Button
                            style={{
                                fontFamily: 'Signika',
                                fontWeight: '600',
                                paddingTop: 10,
                                paddingBottom: 10,
                                backgroundColor: Palette.PRIMARY,
                                borderColor: Palette.PRIMARY,
                                margin: 10,
                                fontSize: isMobile && '13px',
                            }}
                            className="text-uppercase"
                            onClick={handleEditPassword}
                        >
                            Ganti Sandi
                        </Button>
                        {profileData.member?.hpi_id && <Button
                            style={{
                                fontFamily: 'Signika',
                                fontWeight: '600',
                                paddingTop: 10,
                                paddingBottom: 10,
                                backgroundColor: Palette.PRIMARY,
                                borderColor: Palette.PRIMARY,
                                margin: 10,
                                fontSize: isMobile && '13px',
                            }}
                            className="text-uppercase"
                            onClick={handleEdit}
                        >
                            Ubah Profil
                        </Button>}
                        <Button
                            style={{
                                fontFamily: 'Signika',
                                fontWeight: '600',
                                paddingTop: 10,
                                paddingBottom: 10,
                                backgroundColor: Palette.PRIMARY,
                                borderColor: Palette.PRIMARY,
                                margin: 10,
                                fontSize: isMobile && '13px',
                            }}
                            onClick={()=>{
                                let copyText = `https://sihapei.hpi.or.id/anggota/${profileData?.member?.hpi_id}`;
                                navigator.clipboard.writeText(copyText);
                                Swal.fire({
                                    title: 'Link berhasil di salin',
                                    icon: 'success',
                                    confirmButtonText: 'OKE'
                                })
                            }}
                        >
                            SALIN LINK PROFIL
                        </Button>
                    </div>
                )}
            </Row>

            <Row style={{ marginTop: 20 }}>
                <Col>
                    <div
                        style={{
                            fontFamily: 'Signika',
                            fontWeight: '600',
                            fontSize: '0.75em',
                            color: '#8e8e8e',
                            marginBottom: 7,
                        }}
                        className="mt-3 text-uppercase"
                    >
                        KEBERSEDIAAN UNTUK MENAMPILKAN PROFIL DI DIREKTORI PENERJEMAH DAN JURU BAHASA INDONESIA
                    </div>
                    <div>
                        {profileData?.member?.is_public ? "Bersedia" : "Tidak Bersedia"}
                    </div>

                    <div
                        style={{
                            fontFamily: 'Signika',
                            fontWeight: '600',
                            fontSize: '0.75em',
                            color: '#8e8e8e',
                            marginBottom: 7,
                        }}
                        className="mt-3 text-uppercase"
                    >
                        Resume
                    </div>
                    <div>
                        {profileData.member && profileData.member.resume && profileData.member.resume !== "<p><br></p>"
                            ? parse(profileData.member.resume)
                            : '-'}
                    </div>

                    <hr></hr>

                    <div
                        style={{
                            fontFamily: 'Signika',
                            fontWeight: '600',
                            fontSize: '0.75em',
                            color: '#8e8e8e',
                        }}
                        className="mt-3 text-uppercase"
                    >
                        Profesi
                    </div>
                    <div className="mb-3">
                        {(profileData.member && profileData.member.profession)
                            ? TextTranslator.getProfesi(profileData.member.profession)
                            : '-'}
                    </div>

                    <hr></hr>

                    <div
                        style={{
                            fontFamily: 'Signika',
                            fontWeight: '600',
                            fontSize: '0.75em',
                            color: '#8e8e8e',
                            marginBottom: 7,
                        }}
                        className="mt-3 text-uppercase"
                    >
                        Ketersediaan
                    </div>
                    <div>
                        {(profileData.member && profileData.member.availability)
                            ? TextTranslator.getKetersediaan(profileData.member.availability)
                            : '-'}
                    </div>

                    <hr></hr>

                    <div
                        style={{
                            fontFamily: 'Signika',
                            fontWeight: '600',
                            fontSize: '0.75em',
                            color: '#8e8e8e',
                            marginBottom: 7,
                        }}
                        className="mt-3 text-uppercase"
                    >
                        Status Pekerjaan
                    </div>
                    <div>
                        {(profileData.member && profileData.member.occupation)
                            ? TextTranslator.getStatusPekerjaan(profileData.member.occupation)
                            : '-'}
                    </div>

                    <hr></hr>

                    <div
                        style={{
                            fontFamily: 'Signika',
                            fontWeight: '600',
                            fontSize: '0.75em',
                            color: '#8e8e8e',
                        }}
                        className="mt-3 text-uppercase"
                    >
                        Pendidikan
                    </div>
                    <div
                        style={{
                            whiteSpace: 'pre-line',
                        }}
                    >
                        {profileData.member && profileData.member.education && profileData.member.education !== "<p><br></p>"
                            ? parse(profileData.member.education)
                            : '-'}
                    </div>

                    <hr></hr>

                    <div
                        style={{
                            fontFamily: 'Signika',
                            fontWeight: '600',
                            fontSize: '0.75em',
                            color: '#8e8e8e',
                        }}
                        className="mt-3 text-uppercase"
                    >
                        Spesialisasi
                    </div>
                    <div>
                        {profileData.specializations
                            ? profileData.specializations.map((spec) => {
                                return <div>{spec.specialization}</div>
                            })
                            : '-'}
                    </div>

                    <hr></hr>

                    <div
                        style={{
                            fontFamily: 'Signika',
                            fontWeight: '600',
                            fontSize: '0.75em',
                            color: '#8e8e8e',
                        }}
                        className="mt-3 text-uppercase"
                    >
                        Pasangan Bahasa
                    </div>
                    <div>
                        {profileData.languages
                            ? profileData.languages.map((lang) => {
                                return (
                                    <div>
                                        {lang.language_from} ke {lang.language_to} {(lang.certification === "NONE") || (lang.certification === null) ? "" : "(Sertifikasi HPI: " + lang.certification + ")"}
                                    </div>
                                )
                            })
                            : '-'}
                    </div>

                    <hr></hr>

                    <div
                        style={{
                            fontFamily: 'Signika',
                            fontWeight: '600',
                            fontSize: '0.75em',
                            color: '#8e8e8e',
                        }}
                        className="text-uppercase"
                    >
                        Layanan
                    </div>
                    <div>
                        {profileData.member && profileData.member.service_offered && profileData.member.service_offered !== "<p><br></p>"
                            ? parse(profileData.member.service_offered)
                            : '-'}
                    </div>

                    <hr></hr>

                    <div
                        style={{
                            fontFamily: 'Signika',
                            fontWeight: '600',
                            fontSize: '0.75em',
                            color: '#8e8e8e',
                        }}
                        className="mt-3 text-uppercase"
                    >
                        Penerjemah sejak
                    </div>
                    <div>
                        {profileData.member && profileData.member.translator_since
                            ? profileData.member.translator_since
                            : '-'}
                    </div>

                    <hr></hr>

                    <div
                        style={{
                            fontFamily: 'Signika',
                            fontWeight: '600',
                            fontSize: '0.75em',
                            color: '#8e8e8e',
                        }}
                        className="mt-3 text-uppercase"
                    >
                        Juru bahasa sejak
                    </div>
                    <div>
                        {profileData.member && profileData.member.interpreter_since
                            ? profileData.member.interpreter_since
                            : '-'}
                    </div>

                    <hr></hr>

                    <div
                        style={{
                            fontFamily: 'Signika',
                            fontWeight: '600',
                            fontSize: '0.75em',
                            color: '#8e8e8e',
                        }}
                        className="mt-3 text-uppercase"
                    >
                        Preferensi Mata Uang
                    </div>
                    <div>
                        {profileData.member && profileData.member.preferred_currency
                            ? profileData.member.preferred_currency
                            : '-'}
                    </div>

                    <hr></hr>

                    <div
                        style={{
                            fontFamily: 'Signika',
                            fontWeight: '600',
                            fontSize: '0.75em',
                            color: '#8e8e8e',
                        }}
                        className="mt-3 text-uppercase"
                    >
                        Metode pembayaran
                    </div>
                    <div>
                        {profileData.member && profileData.member.payment_method
                            ? profileData.member.payment_method
                            : '-'}
                    </div>

                    <hr></hr>

                    <div
                        style={{
                            fontFamily: 'Signika',
                            fontWeight: '600',
                            fontSize: '0.75em',
                            color: '#8e8e8e',
                        }}
                        className="mt-3 text-uppercase"
                    >
                        Perangkat lunak
                    </div>
                    <div>
                        {profileData.member && profileData.member.software
                            ? profileData.member.software
                            : '-'}
                    </div>

                    <hr></hr>

                    <div
                        style={{
                            fontFamily: 'Signika',
                            fontWeight: '600',
                            fontSize: '0.75em',
                            color: '#8e8e8e',
                        }}
                        className="mt-3 text-uppercase"
                    >
                        Tentang saya
                    </div>
                    <div>
                        {profileData.member && profileData.member.about_me && profileData.member.about_me !== "<p><br></p>"
                            ? parse(profileData.member.about_me)
                            : '-'}
                    </div>

                    <hr></hr>

                    <div
                        style={{
                            fontFamily: 'Signika',
                            fontWeight: '600',
                            fontSize: '0.75em',
                            color: '#8e8e8e',
                        }}
                        className="mt-3 text-uppercase"
                    >
                        Anggota dari
                    </div>

                    <div className="mb-3">
                        {Array.isArray(profileData?.member?.memberscol) &&
                            profileData?.member?.memberscol.length > 0
                            ? profileData?.member?.memberscol.map((obj, idx) => {
                                return (
                                    <>
                                        <div
                                            style={{
                                                backgroundColor: Palette.PRIMARY,
                                                borderRadius: 5,
                                                display: 'inline-block',
                                                minWidth: 100,
                                                color: 'white',
                                                textAlign: 'center',
                                                paddingTop: 3,
                                                paddingBottom: 3,
                                                paddingRight: 5,
                                                paddingLeft: 5,
                                            }}
                                        >
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        flex: 1,
                                                    }}
                                                >
                                                    {obj.organization}
                                                </div>

                                                <div
                                                    style={{
                                                        alignItems: 'center',
                                                        justifyContent: 'center',
                                                    }}
                                                >
                                                    <a href={obj.url} target={'_blank'}>
                                                        <AiOutlineLink
                                                            style={{
                                                                color: 'white',
                                                                marginRight: 5,
                                                                marginLeft: 10,
                                                            }}
                                                        />
                                                    </a>
                                                </div>
                                            </div>
                                        </div>

                                        {/*<a href={obj.location}>*/}
                                        {/*    <Button*/}
                                        {/*        size={"sm"}*/}
                                        {/*        style={{*/}
                                        {/*            */}
                                        {/*            borderColor: Palette.PRIMARY,*/}
                                        {/*        }}*/}
                                        {/*    >*/}
                                        {/*        */}
                                        {/*    </Button>*/}
                                        {/*</a>*/}
                                    </>
                                )
                            })
                            : '-'}
                    </div>

                    <hr></hr>

                    <div
                        style={{
                            fontFamily: 'Signika',
                            fontWeight: '600',
                            fontSize: '0.75em',
                            color: '#8e8e8e',
                        }}
                        className="mt-3 text-uppercase"
                    >
                        Berkas
                    </div>
                    <div className="mb-3">
                        {Array.isArray(profileData?.member?.files) &&
                            profileData?.member?.files.length > 0
                            ? profileData?.member?.files.map((obj, idx) => {
                                return (
                                    <>
                                        <div
                                            style={{
                                                backgroundColor: Palette.PRIMARY,
                                                borderRadius: 5,
                                                display: 'inline-block',
                                                minWidth: 100,
                                                color: 'white',
                                                textAlign: 'center',
                                                paddingTop: 3,
                                                paddingBottom: 3,
                                                paddingRight: 5,
                                                paddingLeft: 5,
                                            }}
                                        >
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        flex: 1,
                                                    }}
                                                >
                                                    {obj.name}
                                                </div>

                                                <div
                                                    style={{
                                                        alignItems: 'center',
                                                        justifyContent: 'center',
                                                    }}
                                                >
                                                    <a href={obj.location}>
                                                        <FaDownload
                                                            style={{
                                                                color: 'white',
                                                                marginRight: 5,
                                                                marginLeft: 10,
                                                            }}
                                                        />
                                                    </a>
                                                </div>
                                            </div>
                                        </div>

                                        {/*<a href={obj.location}>*/}
                                        {/*    <Button*/}
                                        {/*        size={"sm"}*/}
                                        {/*        style={{*/}
                                        {/*            */}
                                        {/*            borderColor: Palette.PRIMARY,*/}
                                        {/*        }}*/}
                                        {/*    >*/}
                                        {/*        */}
                                        {/*    </Button>*/}
                                        {/*</a>*/}
                                    </>
                                )
                            })
                            : '-'}
                    </div>
                </Col>
            </Row>

            <EditProfilePictureModal
                close={(refresh) => {
                    if (refresh) {
                        fetchSelfData()
                    }
                    setIsUpdatingProfilePicture(false)
                }}
                show={isUpdatingProfilePicture}
            />
        </>
    )

    return (
        <Container className="text-left">
            <Tabs defaultActiveKey="profile" className="mb-3">
                <Tab
                    eventKey="profile"
                    title={
                        <div className={"d-flex flex-row align-items-center"}>
                            <>Profil&nbsp;</>
                            {
                                profileStatus !== "NONE" &&
                                <OverlayTrigger
                                    placement="right"
                                    delay={{ show: 250, hide: 400 }}
                                    overlay={<Tooltip id="button-tooltip2">
                                        {
                                            profileStatus === "WARNING" ?
                                                "Profil belum lengkap diisi"
                                                :
                                                "Beberapa fitur tidak bisa dipakai jika data profil wajib belum dilengkapi."
                                        }
                                    </Tooltip>}
                                >
                                    {
                                        profileStatus === "WARNING" ?
                                            <IoMdWarning color={"orange"} />
                                            :
                                            <MdError color={"red"} />
                                    }
                                </OverlayTrigger>
                            }

                        </div>
                    }
                    children={() => <MembershipPage props={this.props} />}
                >
                    {profile}
                </Tab>
                <Tab eventKey="membership" title={<div className={"d-flex flex-row align-items-center"}>
                    <>Info Keanggotaan&nbsp;</>
                    {
                        !isMembershipComplete &&
                        <OverlayTrigger
                            placement="right"
                            delay={{ show: 250, hide: 400 }}
                            overlay={<Tooltip id="button-tooltip">
                                Beberapa fitur tidak bisa dipakai jika data keanggotaan belum dilengkapi.
                            </Tooltip>}
                        >
                            <MdError color={"red"} />
                        </OverlayTrigger>
                    }
                </div>}>
                    <MembershipPage props={props} />
                </Tab>
                <Tab eventKey="certificate" title={<div className={"d-flex flex-row align-items-center"}>
                    <>Info Sertifikat&nbsp;</>
                </div>}
                >
                    <MemberCertificatePage />
                </Tab>

            </Tabs>
        </Container>
    )
}

export default ProfilePage
