import React from 'react'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import logoHPI from '../assets/logo.png'
import Palette from '../Palette'

function Footer(props) {
    const { isMobile } = props

    return (
        <Row
            style={{
                boxShadow: '0 0px 8px rgba(100,100,100,.25)',
                backgroundColor: 'white',
            }}
            className="mt-3"
        >
            <Col>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        width: '100%',
                    }}
                >
                    <img
                        src={logoHPI}
                        style={{
                            width: 65,
                            height: 65,
                            objectFit: 'contain',
                            margin: '15px',
                            marginBottom: 0,
                        }}
                        alt="HPI"
                    />
                </div>
                {!isMobile && (
                    <>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: isMobile ? 'column' : 'row',
                                justifyContent: isMobile ? 'flex-start' : 'center',
                                width: '100%',
                            }}
                        >
                            <a
                                href={'https://hpi.or.id'}
                                style={{
                                    color: Palette.PRIMARY,
                                    fontWeight: '600',
                                    marginBottom: 15,
                                    fontFamily: 'Titillium Web',
                                }}
                            >
                                Main Site
                            </a>

                            <span
                                style={{
                                    marginLeft: 15,
                                    marginRight: 15,
                                    color: Palette.PRIMARY,
                                    marginBottom: 15,
                                }}
                            >
                                |
                            </span>

                            <a
                                href={'https://www.hpi.or.id/sejarah'}
                                style={{
                                    color: Palette.PRIMARY,
                                    fontWeight: '600',
                                    marginBottom: 15,
                                    fontFamily: 'Titillium Web',
                                }}
                            >
                                About Us
                            </a>

                            <span
                                style={{
                                    marginLeft: 15,
                                    marginRight: 15,
                                    color: Palette.PRIMARY,
                                    marginBottom: 15,
                                }}
                            >
                                |
                            </span>

                            <a
                                href={'http://sihapei.hpi.or.id/terms-of-use'}
                                style={{
                                    color: Palette.PRIMARY,
                                    fontWeight: '600',
                                    marginBottom: 15,
                                    fontFamily: 'Titillium Web',
                                }}
                            >
                                Terms & Conditions
                            </a>

                            {/*<span*/}
                            {/*    style={{*/}
                            {/*        marginLeft: 15,*/}
                            {/*        marginRight: 15,*/}
                            {/*        color: Palette.PRIMARY,*/}
                            {/*        marginBottom: 15,*/}
                            {/*    }}*/}
                            {/*>*/}
                            {/*    |*/}
                            {/*</span>*/}

                            {/*<Link*/}
                            {/*    to={'/privacy-policy'}*/}
                            {/*    style={{*/}
                            {/*        color: Palette.PRIMARY,*/}
                            {/*        fontWeight: '600',*/}
                            {/*        marginBottom: 15,*/}
                            {/*        fontFamily: 'Titillium Web',*/}
                            {/*    }}*/}
                            {/*>*/}
                            {/*    Kebijakan Privasi*/}
                            {/*</Link>*/}

                            <span
                                style={{
                                    marginLeft: 15,
                                    marginRight: 15,
                                    color: Palette.PRIMARY,
                                    marginBottom: 15,
                                }}
                            >
                                |
                            </span>

                            <a
                                href={'https://www.hpi.or.id/hubungi-kami'}
                                style={{
                                    color: Palette.PRIMARY,
                                    fontWeight: '600',
                                    marginBottom: 15,
                                    fontFamily: 'Titillium Web',
                                }}
                            >
                                Contact Us
                            </a>
                        </div>
                    </>
                )}
            </Col>
        </Row>
    )
}

export default Footer
