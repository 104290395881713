import GeneralRequest from "../util/GeneralRequest";
import ApiRequest from "../util/ApiRequest";

export default class MemberCerification {

    static selfCertification = async () => {
        return await ApiRequest.set('v1/my_member_certification', "GET");
    }

}
