import ApiRequest from '../util/ApiRequest'

export default class Specialization {
    getAllSpecialization = async () => {
        return await ApiRequest.set('/v1/specializations', 'GET')
    }
    getAllAvailableSpecialization = async () => {
        return await ApiRequest.set('/v1/specializations/available', 'GET')
    }
}
