import {Button, FormControl, InputGroup, Modal} from "react-bootstrap";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import {AiOutlineClose} from "react-icons/all";
import Palette from "../../Palette";
import Col from "react-bootstrap/Col";
import React, {useState} from "react";
import {MdArchive, MdKeyboard} from "react-icons/md";
import Upload from "../../models/Upload";
import CustomDropzone from "../Reusable/CustomDropzone";
import Swal from "sweetalert2";
import Member from "../../models/Member";

export default function EditProfileUploadModal(props) {

    const {show, close, onSubmit} = props

    const [isEditFilesFocused, setEditFilesFocused] = useState(false)
    const [isEditNameFocused, setIsEditNameFocused] = useState(false)

    const [name, setName] = useState(null)
    const [imageUrl, setImageUrl] = useState(null)

    const [isLoading, setIsLoading] = useState(false)

    const resetAndClose = (refresh) => {
        reset()
        close(refresh)
    }

    const reset = () => {
        setName(null)
        setImageUrl(null)
    }

    const onBannerPicked = async function (image) {

        try {
            const model = new Upload();

            const formData = new FormData();

            formData.append('upload', image, image.name)
            formData.append('member_id', "-1")

            const result = await model.uploadImage(formData);
            console.log(result)
            setImageUrl(result.location)

        } catch (e) {
            console.error(e)
        }


    }

    const handleSave = async () => {

        let memberModel = new Member()

        try {
            let result = await memberModel.editMemberData({
                profile_picture_url : imageUrl
            })

            console.log(result)

            await Swal.fire({
                title: 'Foto profil tersimpan',
                icon: 'success',
                confirmButtonText: 'OKE'
            })

        } catch (e) {
            console.log(e)
        }
    }


    return <Modal show={show} onHide={() => {
        resetAndClose()
    }}
    >
        <Container>
            <Row style={{
                marginTop: 15,
                marginRight: 15,
                display: 'flex',
                justifyContent: 'flex-end',
                marginBottom: -30
            }}>
                <AiOutlineClose size={25} style={{cursor: 'pointer'}} onClick={() => {
                    resetAndClose()
                }}/>
            </Row>

            <Container>
                <Row style={{display: 'flex', justifyContent: 'center', marginTop: 25, marginBottom: 15}}>

                    <Col md={12}>
                        <CustomDropzone
                            // width={"500px"}
                            height={"400px"}
                            // id={"reference-component"}
                            aspect={1}
                            imageSrc={imageUrl}
                            loading={isLoading}
                            prompt={<div style={{fontFamily: "Signika"}}>Ganti foto profil<br/>(ukuran yang dianjurkan: 250x250)</div>}
                            onDrop={acceptedFiles => onBannerPicked(acceptedFiles)}
                        />
                    </Col>

                </Row>

                <Row>
                    <Col md={12} style={{
                        marginBottom: 15,
                        display: "flex",
                        justifyContent: "flex-end"
                    }}>
                        <Button
                            onClick={async () => {
                                await handleSave()
                                resetAndClose(true)
                            }}
                            disabled={!imageUrl}
                            style={{
                                backgroundColor: Palette.PRIMARY,
                                borderColor: Palette.PRIMARY,
                                fontFamily: 'Signika',
                            }}
                            size={"sm"}>Simpan</Button>
                    </Col>
                </Row>
            </Container>

        </Container>
    </Modal>

}
