import {Button, FormControl, InputGroup, Modal} from "react-bootstrap";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import {AiOutlineClose} from "react-icons/all";
import Palette from "../../Palette";
import Col from "react-bootstrap/Col";
import React, {useState} from "react";
import {MdArchive, MdKeyboard} from "react-icons/md";
import Upload from "../../models/Upload";
import Swal from "sweetalert2";
import Spinner from "react-bootstrap/Spinner";

export default function EditProfileUploadModal(props) {
    const MAX_FILE_SIZE = (10 * 1024 * 1024);
    const {show, close, onSubmit} = props

    const [isEditFilesFocused, setEditFilesFocused] = useState(false)
    const [isEditNameFocused, setIsEditNameFocused] = useState(false)

    const [name, setName] = useState(null)
    const [imageUrl, setImageUrl] = useState(null)

    const [isLoading, setIsLoading] = useState(false)

    const resetAndClose = () =>{
        reset()
        close()
    }

    const reset =()=>{
        setName(null)
        setImageUrl(null)
    }

    const handleUploadFile = async(event) => {

        setIsLoading(true)

        console.log(event)

        try{
            const model = new Upload();
            if (event.target.files[0] && event.target.files[0].size > MAX_FILE_SIZE) {
                Swal.fire('', 'Ukuran file terlalu besar. Ukuran maksimum yang diizinkan adalah 10 MB.', 'error')
                return
            }
            const formData = new FormData();

            console.log("ETF", event.target.files[0])

            formData.append('upload', event.target.files[0])
            formData.append('member_id', "-1")

            const result = await model.uploadImage(formData);
            setImageUrl(result.location)
            setIsLoading(false)

        }catch (e) {
            setIsLoading(false)
            console.log("ETF ERROR", e)
        }

    }

    return <Modal show={show} onHide={() => {
        resetAndClose()
    }}
    >
        <Container>
            <Row style={{
                marginTop: 15,
                marginRight: 15,
                display: 'flex',
                justifyContent: 'flex-end',
                marginBottom: -30
            }}>
                <AiOutlineClose size={25} style={{cursor: 'pointer'}} onClick={() => {
                    resetAndClose()
                }}/>
            </Row>

            <Container>
                <Row style={{display: 'flex', justifyContent: 'center', marginTop: 25, marginBottom: 15}}>

                    <Col md={12}>
                        <div
                            style={{
                                fontFamily: 'Signika',
                                fontWeight: '600',
                                fontSize: '0.75em',
                                color: '#8e8e8e',
                                marginBottom: 7,
                            }}
                            className="mt-3 text-uppercase"
                        >
                            Upload File
                        </div>
                    </Col>

                    <Col
                        style={{
                            paddingBottom: 10
                        }}
                        md={12}>
                        <InputGroup
                            style={
                                isEditNameFocused
                                    ? {
                                        boxShadow: '0 0 10px #9ecaed',
                                        border: '1px solid  #9ecaed',
                                        borderRadius: 5,
                                    }
                                    : {}
                            }
                        >
                            <InputGroup.Prepend>
                                <InputGroup.Text
                                    style={{
                                        borderWidth: 0,
                                        display: 'flex',
                                        alignItems: 'center',
                                        backgroundColor: '#e9f3f4',
                                    }}
                                >
                                    <MdKeyboard size={20}/>
                                </InputGroup.Text>

                                <InputGroup.Text
                                    style={{
                                        borderWidth: 0,
                                        display: 'flex',
                                        alignItems: 'center',
                                        paddingLeft: 0,
                                        paddingRight: 0,
                                    }}
                                >
                                    <div
                                        style={{
                                            height: '75%',
                                            width: 2,
                                            backgroundColor: '#9ca4a6',
                                        }}
                                    />
                                </InputGroup.Text>
                            </InputGroup.Prepend>

                            <FormControl
                                placeholder={
                                    'Document Name (Ex: CV, Portfolio, etc)'
                                }
                                style={{
                                    borderWidth: 0,
                                    backgroundColor: '#e9f3f4',
                                    outline: 'none',
                                    boxShadow: 'none',
                                    fontFamily: 'Signika',
                                    fontWeight: '600',
                                }}
                                onChange={(event) => setName(event.target.value)}
                                onFocus={() => setIsEditNameFocused(true)}
                                onBlur={() => setIsEditNameFocused(false)}
                                value={name}
                            />
                        </InputGroup>
                    </Col>

                    <Col md={12}>
                        <InputGroup
                            style={
                                isEditFilesFocused
                                    ? {
                                        boxShadow: '0 0 10px #9ecaed',
                                        border: '1px solid  #9ecaed',
                                        borderRadius: 5,
                                        flexWrap: 'nowrap',
                                    }
                                    : {
                                        flexWrap: 'nowrap',
                                    }
                            }
                        >
                            <InputGroup.Prepend>
                                <InputGroup.Text
                                    style={{
                                        borderWidth: 0,
                                        alignItems: 'center',
                                        backgroundColor: '#e9f3f4',
                                    }}
                                >
                                    <MdArchive size={20}/>
                                </InputGroup.Text>

                                <InputGroup.Text
                                    style={{
                                        borderWidth: 0,
                                        paddingLeft: 0,
                                        paddingRight: 0,
                                    }}
                                >
                                    <div
                                        style={{
                                            width: 2,
                                            backgroundColor: '#9ca4a6',
                                        }}
                                    />
                                </InputGroup.Text>
                            </InputGroup.Prepend>

                            <FormControl
                                placeholder={'Upload your files'}
                                style={{
                                    borderWidth: 0,
                                    backgroundColor: '#e9f3f4',
                                    outline: 'none',
                                    boxShadow: 'none',
                                    fontFamily: 'Signika',
                                    fontWeight: '600',
                                }}
                                type="file"
                                multiple={false}
                                onChange={handleUploadFile}
                                // onChange={(event) => setEditFiles(event.target.files)}
                                onFocus={() => setEditFilesFocused(true)}
                                onBlur={() => setEditFilesFocused(false)}
                            />
                        </InputGroup>
                    </Col>
                </Row>

                <Row>
                    <Col md={12} style={{
                        marginBottom : 15,
                        display : "flex",
                        justifyContent : "flex-end"
                    }}>
                        <Button
                            onClick={()=>{
                                onSubmit({
                                    name : name,
                                    location : imageUrl
                                })
                                resetAndClose()
                            }}
                            disabled={!name || !imageUrl}
                            style={{
                                backgroundColor: Palette.PRIMARY,
                                borderColor: Palette.PRIMARY,
                                fontFamily: 'Signika',
                            }}
                            size={"sm"}>{isLoading ? <> <Spinner size="sm" height="1rem" width="1rem" animation="border"/></> : "+ Add"}</Button>
                    </Col>
                </Row>
            </Container>

        </Container>
    </Modal>

}
