export default class Palette {
    //static PRIMARY = '#284A9A'
    static PRIMARY = '#0000FF'
    static SECONDARY = '#FFBD59'

    static YELLOW = '#FFEF95'
    static AQUA = '#98FBFF'
    static GREY = '#1F1F1F'
    static RED = '#FF0000'

    static GOLD = "#CCA811"
    static NEON_BLUE = "#0000FF"

}
