import ApiRequest from '../util/ApiRequest'

export default class Content {
    getAll = async () => {
        return await ApiRequest.set('/v1/contents', 'GET')
    }

    getById = async (id) => {
        return await ApiRequest.set(`/v1/content/${id}`, 'GET')
    }
}
