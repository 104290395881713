import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import React from 'react'
import { withStyles } from '@material-ui/core'

export default function CustomButton(props) {
    return (
        <Button
            style={{
                fontFamily: 'Montserrat',
                ...props.style,
            }}
            {...props}
            onMouseDown={(e) => e.preventDefault()}
        >
            {props.children}
        </Button>
    )
}
