import { Button, Container, FormControl, InputGroup, Modal, ModalBody, ModalFooter, ModalTitle, Row } from "react-bootstrap";
import { AiFillWarning } from "react-icons/all";
import { useHistory } from "react-router-dom";
export default function ReminderDebtModal({ isOpen, close, message }) {
    const handleClose = (deleteState) => {
        close(deleteState)
    }
    const history = useHistory()
    return (
        <>
            <Modal show={isOpen}>
                <Container>
                    <Row className="justify-content-center">
                        <div className="text-warning mt-2" style={{ fontSize: '5em' }}>
                            <AiFillWarning />
                        </div>
                        <ModalTitle>
                            <h3 className="mb-3"></h3>
                        </ModalTitle>
                        <ModalBody>
                            <div className="text-center">
                                <p>{message}</p>
                            </div>
                        </ModalBody>

                    </Row>

                    <ModalFooter style={{border:'none'}} className="d-block text-center">
                        <Button onClick={handleClose} variant="secondary">Mengerti</Button>
                    </ModalFooter>


                </Container>

            </Modal>
        </>
    )
}