export default class TextTranslator{
    static getStatusPekerjaan = (status) =>{

        if(status?.toUpperCase() === "ORGANIZATION"){
            return "Pegawai Perusahaan"
        }
        if(status?.toUpperCase() === "FREELANCE"){
            return "Pekerja Lepas"
        }

        return status
    }
    static getJenisKelamin = (status) =>{

        if(status?.toUpperCase() === "M"){
            return "Laki-laki"
        }
        if(status?.toUpperCase() === "F"){
            return "Perempuan"
        }

        return status
    }

    static getKetersediaan = (status) =>{
        if(status?.toUpperCase() === "FULL-TIME"){
            return "Purnawaktu"
        }
        if(status?.toUpperCase() === "PART-TIME"){
            return "Paruh waktu"
        }
        return status
    }

    static getProfesi = (status) =>{
        if(status?.toUpperCase() === "TRANSLATOR"){
            return "Penerjemah"
        }
        if(status?.toUpperCase() === "INTERPRETER"){
            return "Juru Bahasa"
        }
        if(status?.toUpperCase() === "BOTH"){
            return "Keduanya"
        }
        return status
    }

    static getTipeProduk = (status) =>{
        if(status?.toUpperCase() === "MEMBERSHIP"){
            return "Iuran Tahunan"
        }
        if(status?.toUpperCase() === "CLASS"){
            return "Acara"
        }
        if(status?.toUpperCase() === "FIRST_MEMBERSHIP"){
            return "Keanggotaan Pertama"
        }
        if(status?.toUpperCase() === "UPGRADE_MEMBERSHIP"){
            return "Peningkatan Status"
        }
        return status
    }

}
